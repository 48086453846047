import "../../app/globals.css";
import { Box } from "@mui/material";

export const AppLayout = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 1,
        background:
          "radial-gradient(circle at 100% 100%, rgba(151, 71, 255,0.25) 0%, transparent 30%) no-repeat,radial-gradient(circle at 100% 0%, rgba(189, 254, 164, 0.25) 7%, transparent 18%) no-repeat 100% 0,#16181A",
      }}
    >
      {children}
    </Box>
  );
};
