import { Box, Divider, Link, styled, Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import { CreatedButton, CustomButton, Field } from '../styles'
import { Pagination } from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import Shield from '../assets/shield.png'
import Purple from '../assets/purple.svg'
import Green from '../assets/green.svg'
import Red from '../assets/red.svg'
import CoinPurple from '../assets/coin-purple.svg'
import CoinGreen from '../assets/coin-green.svg'
import CoinRed from '../assets/coin-red.svg'
import Sino from '../assets/sino.png'
import { Link as RouterLink } from 'react-router-dom'

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { ParkOutlined } from '@mui/icons-material'
import { Card, Col, Container, Row } from 'react-bootstrap'
const WalletAdmin = () => {
  const [tokenList, setTokenList] = useState([])
  const [creditAmount, setCreditAmount] = useState(0)
  const user = JSON.parse(localStorage.getItem('user'))

  const userToken = localStorage.getItem('userToken')
  const token = JSON.parse(userToken)

  const fetchTokenList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/tokens/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setTokenList(response.data.response)
      setCreditAmount(response.data.response.creditBalance.ownedAmount)
    } catch (error) {
      console.error(error)
    }
  }

  const [transactionList, setTransactionList] = useState([])

  const fetchTransactionList = async () => {
    try {
      const response = await axios.post(
        `https://dev-back.itcarbon.org/api/tokens/transaction/`,
        { userId: user.id }
      )
      setTransactionList(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTransactionList()
  }, [])

  function converterParaData(data) {
    const dataObjeto = new Date(data)
    const options = { timeZone: 'America/Sao_Paulo' }
    const dataBrasileira = dataObjeto.toLocaleDateString('pt-BR', options)
    return dataBrasileira
  }

  useEffect(() => {
    fetchTokenList()
  }, [])

  const fetchConverter = async (data) => {
    try {
      const response = await axios.post(
        'https://dev-back.itcarbon.org/api/tokens/trade-request/',
        {
          quantity: tokenList.tpc.quantity
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  }

  const onAdd = async () => {
    try {
      const response = await axios.put(
        'https://dev-back.itcarbon.org/api/wallets/',
        {
          amount: 1000,
          paidValue: 1000 * 10
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
      setCreditAmount((prevCreditAmount) => prevCreditAmount + 10000)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DashboardLayout email={user.email} name={user.username} role={user.role}>
      <Container>
          <div>
          <Box 
          className="pt-2 pb-3 "
        sx={{
          borderRadius: 2,
          padding: 3,
          margin: 0,
          backgroundColor: '#FFFFFF',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box></Box>
        <Box>
          <IconButton>
            <img src={Sino} style={{ borderRadius: '50%', width: '50px' }} />
          </IconButton>
          <IconButton>
            <img src={Shield} style={{ borderRadius: '50%', width: '50px' }} />
          </IconButton>
        </Box>
      </Box>
      
            <Row className='mt-5'>
                <Col md="4 mt-5">
                 <Box
          sx={{
            padding: '2rem',
            backgroundColor: '#272727',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            borderRadius: '13px',
          }}
        >
          <Box sx={{ marginTop: '-70px' }}>
            <img
              style={{
                backgroundColor: '#272727',
                padding: '15px',
                borderRadius: '50px'
              }}
              src={Purple}
              width={70}
            ></img>
          </Box>
          <Typography fontWeight="500" color="#FFFFFF" fontSize={23}>
            {tokenList.tcc ? tokenList.tcc.quantity : 0}{' '}
            {tokenList.tcc ? tokenList.tcc.type : 'TCC'}
          </Typography>
        </Box>
                </Col>
                <Col md="4 mt-5">
                    <Box
          sx={{
            padding: '2rem',
            backgroundColor: '#272727',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            borderRadius: '13px',
          }}
        >
          <Box sx={{ marginTop: '-70px' }}>
            <img
              style={{
                backgroundColor: '#272727',
                padding: '15px',
                borderRadius: '50px'
              }}
              src={Green}
              width={70}
            ></img>
          </Box>
          <Typography fontWeight="500" color="#FFFFFF" fontSize={23}>
            {tokenList?.tpc ? tokenList?.tpc?.quantity : 0}{' '}
            {tokenList?.tpc ? tokenList?.tpc?.type : 'TPC'}
          </Typography>
        </Box>
  
                </Col>


                <Col md="4 mt-5">

<Box
          sx={{
            padding: '2rem',
            backgroundColor: '#272727',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            borderRadius: '13px',
          }}
        >
          <Box sx={{ marginTop: '-70px' }}>
            <img
              style={{
                backgroundColor: '#272727',
                padding: '15px',
                borderRadius: '50px'
              }}
              src={Red}
              width={70}
            ></img>
          </Box>
          <Typography fontWeight="500" color="#FFFFFF" fontSize={23}>
            {tokenList.tdc ? tokenList.tdc.quantity : 0}{' '}
            {tokenList.tdc ? tokenList.tdc.type : 'TDC'}
          </Typography>
        </Box>
                </Col>
            </Row>
          </div>

      </Container>
 
 <Container>
                <Col className='d-flex mt-4 mb-5'>
                <input
            type="text"
            placeholder="Pesquisar"
            style={{
              backgroundColor: '#FAAAA',
              height: '45px',
              width: '100%',
              borderRadius: '8px',
              border: '2px solid #EAEAEA',
              padding: '1rem'
            }}
          />
          <IconButton
            sx={{
              backgroundColor: '#9747FF',
              fontSize: '15px',
              color: '#FFFFFF',
              width: '7rem',
              borderRadius: '10px',
              marginLeft: '0.5rem'
            }}
          >
            Filtrar
          </IconButton>
                </Col>

      <Box 
        sx={{
          marginTop: '0px',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px'
        }}
      >
      
          <TableContainer
            sx={{
              borderRadius: 2,
              marginBottom: 3,
              backgroundColor: '#FFFFFF',
              width: '100%'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Token</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell sx={{ width: 140 }}>Valor</TableCell>
                  <TableCell>De</TableCell>
                  <TableCell>Para</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionList.map((transaction, index) => (
                  <TableRow key={transaction.id}>
                    <TableCell
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 0.8
                      }}
                    >
                      {transaction.transactionType === 'TCC' && (
                        <img src={CoinPurple} width="100%" />
                      )}
                      {transaction.transactionType === 'TPC' && (
                        <img src={CoinGreen} width="100%" />
                      )}
                      {transaction.transactionType === 'TDC' && (
                        <img src={CoinRed} width="100%" />
                      )}
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: '#6F7380',
                          marginTop: -0.6
                        }}
                      >
                        {transaction.transactionType}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ color: '#6F7380' }}>
                      {converterParaData(transaction?.createdAt)}
                    </TableCell>
                    <TableCell sx={{ color: '#6F7380' }}>
                      {transaction.amount}
                    </TableCell>
                    <TableCell sx={{ color: '#6F7380' }}>
                      {transaction.to.address && (
                        <Link
                          target="_blank"
                          sx={{ color: '#6F7380' }}
                          underline="none"
                          href={`https://mumbai.polygonscan.com/address/${transaction.to.address}#tokentxns`}
                        >
                          {transaction.to.address}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell sx={{ color: '#6F7380' }}>
                      {transaction.from.address && (
                        <Link
                          target="_blank"
                          sx={{ color: '#6F7380' }}
                          underline="none"
                          href={`https://mumbai.polygonscan.com/address/${transaction.from.address}#tokentxns`}
                        >
                          {transaction.from.address}
                        </Link>
                      )}
                    </TableCell>
                  
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
 </Container>

    </DashboardLayout>
  )
}

export default WalletAdmin
