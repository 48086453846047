import { MenuNav } from '../components/navbar/index'
import { Helmet } from 'react-helmet'
import '../app/globals.css'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import GoldCoin from '../assets/goldcoin.svg'
import backgroundImage from '../assets/LooperGroup.png'
import CardList from '../components/cardList'
import Footer from '../components/footer'
import { ArrowForward } from '@mui/icons-material'
import { CustomButton } from '../styles'
import { useNavigate } from 'react-router-dom'

const Home = () => {

  let navigate = useNavigate()

  const handleSignin = () => {
    navigate('/signin')
  }

  const handleSignup = () => {
    navigate('/signup')
  }

    return (
    <>
    <div style={{
          width: '100%',
          height: '100%',
        background:   'radial-gradient(circle at 120% 70%, rgba(189, 254, 164, 0.25) 0%, transparent 30%) no-repeat,radial-gradient(circle at -18% 100%, rgba(151, 71, 255,0.25) 11%, transparent 27%) no-repeat 100% 0, radial-gradient(circle at -18% 30%, rgba(151, 71, 255,0.25) 9%, transparent 24%) no-repeat 100% 0, radial-gradient(circle at -15% 54%, rgba(189, 254, 164, 0.25) 9%, transparent 24%) no-repeat 100% 0, radial-gradient(circle at 110% 14%, rgba(151, 71, 255,0.25) 9%, transparent 20%) no-repeat 100% 0,  radial-gradient(circle at 68% -24%, rgba(189, 254, 164, 0.25) 14%, transparent 44%) no-repeat 100% 0,#16181A'}} >
     <Helmet>
        <title>Início | Portal de Transparência de Carbono</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      </Helmet>

    <MenuNav/>
        <Container>
            <Col className='mx-auto d-block text-center mb-5' sm="8">

           <h1 className='display-2 mt-5'
              style={{
                width: { xs: '58%', xl: '56%' },
                fontSize: { xs: '57px', xl: '70px' },
                lineHeight: { xs: '70px', xl: '97px' },
                letterSpacing: { xs: '2px', xl: '4px' },
                fontWeight: 'bold',
                color: '#FFFFFF',
              }}
            >
              Conexões verdes para um mundo mais{' '}
              <span className="degrade">sustentável</span>
            </h1>
            <CustomButton
              secondarycolor={'#BDFEA4'}
              className='mt-2'
              onClick={handleSignin}
              sx={{
                fontWeight: '700',
                width: { xs: '160px', xl: '204px' },

                height: { xs: '37px', xl: '44px' },
                fontSize: { xs: 11, xl: 16 }
              }}
            >
              Faça sua parte
              <ArrowForward
                sx={{
                  marginLeft: '7px',
                  width: { xs: '15px', xl: '22px' },
                  color: '#000000'
                }}
              />
            </CustomButton>
            </Col>

        </Container>
        <Container fluid>
            <Row className='text-white mt-5 pt-5 ' style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
            }}>
            <Col sm="1"></Col>

                <Col sm={4} className="d-none d-sm-block">
          <img className="w-75" src={GoldCoin} alt="Gold Coin" />
        </Col>
        <Col sm="2"></Col>
                <Col sm="5">
                <h1
                className='display-6'
                style={{
                  fontSize: { xs: '36px', xl: '48px' },
                  fontWeight: '700',
                  color: '#FFFFFF',
                  textAlign: 'right'
                }}
              >
                Seja protagonista da mudança e contribua para a diferença
              </h1>
              <h5
              className='pt-4'
                style={{
                  width: { xs: '470px', xl: '670px' },
                  color: '#FFFFFF',
                  textAlign: 'right'
                }}
              >
                Com reservas comprovadas e rigorosa validação das empresas
                envolvidas, você pode ter certeza de que suas transações
                contribuem para a redução das emissões de gases de efeito
                estufa.
              </h5>
              <CustomButton
              className='float-end mt-4'
              secondarycolor={'#BDFEA4'}
              onClick={handleSignup}
              sx={{
                fontWeight: '700',
                width: { xs: '160px', xl: '204px' },

                height: { xs: '37px', xl: '44px' },
                fontSize: { xs: 11, xl: 16 }
              }}
            >
              Faça a diferença! 
              <ArrowForward
                sx={{
                  marginLeft: '7px',
                  width: { xs: '15px', xl: '22px' },
                  color: '#000000'
                }}
              />
            </CustomButton>
                </Col>
            </Row>
        </Container>
        <Container className='mt-5 pt-3 mb-5'>
            <CardList title={'Empresas que mais compensaram carbono'} />

            </Container>

          <Footer/>
    </div>
    
    </>
        )
    }
    export default Home
    