import { Box, Link, styled, Typography } from "@mui/material";
import { CustomButton, Field } from "../styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoWhite from "../assets/logowhite.svg";

export default function RedefinePassword() {
  const [concluid, setConcluid] = useState(1);


  const handleNext = () => {
    setConcluid(2);
  };
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/signin");
  };

  const handleBack = () => {
    navigate("/check_email");
  };

  return (
    <Box>
      {/* <Head>
        <title>Redefinir senha | Portal de Transparência de Carbono</title>
        <meta name="description" content="Redefinir senha" />
      </Head> */}
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, #9747FF, #4E3972, #31343B)",
          height: "100vh",
          width: "100%",
          display: "flex",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <img src={LogoWhite} width={"400px"}></img>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {concluid === 1 && (
            <Box>
              <Typography fontWeight={500} color={"#525252"} fontSize={32}>
                Redefinir senha
              </Typography>

              <Box sx={{ marginTop: "2rem", width: "60vh" }}>
                <Typography
                  sx={{ marginBottom: "0.5rem" }}
                  color={"#31343B"}
                  fontWeight={500}
                  fontSize={15}
                >
                  Nova senha
                </Typography>
                <Field />

                <Typography
                  sx={{ marginBottom: "0.5rem", marginTop: "1rem" }}
                  color={"#31343B"}
                  fontWeight={500}
                  fontSize={15}
                >
                  Confirmar senha
                </Typography>
                <Field />
              </Box>
              <Box>
                <CustomButton onClick={handleNext} sx={{ marginTop: "2rem" }}>
                  Salvar
                </CustomButton>
                <CustomButton
                  onClick={handleBack}
                  sx={{ marginTop: "0.8rem" }}
                  secondarycolor="#BDFEA4"
                >
                  Voltar
                </CustomButton>
              </Box>
            </Box>
          )}
          {concluid === 2 && (
            <Box
              sx={{
                width: "100%",
                borderTopLeftRadius: "30px",
                borderBottomLeftRadius: "30px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <img src="concluido.svg" width={"150px"}></img>
                <Typography fontWeight={500} color={"#525252"} fontSize={32}>
                  Senha redefinida com sucesso!
                </Typography>
                <CustomButton onClick={handleSubmit} sx={{ marginTop: "1rem" }}>
                  Fazer login
                </CustomButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
