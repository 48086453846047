import { AppLayout } from '../components/appLayout'
import { useNavigate } from 'react-router-dom'
import { CustomButton, Field } from '../styles'
import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  styled,
  Typography
} from '@mui/material'
// import Head from "next/head";
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from 'axios'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'

const formValidation = yup.object({
  email: yup.string().required('O email é obrigatório'),
  password: yup.string().required('A senha é obrigatória')
})

export default function SignIn() {
  const {
    setError,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidation)
  })

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const words = ['sustentável', 'equilibrado', 'responsável', 'bonito']
  const [currentWordIndex, setCurrentWordIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [])
  let navigate = useNavigate()
  const handleNavigation = () => {
    navigate('/signup')
  }

  const handleLogin = async (data) => {
    try {
      const response = await axios.post(
        'https://dev-back.itcarbon.org/api/auth/login',
        {
          email: data.email,
          password: data.password
        }
      )
      localStorage.setItem('user', JSON.stringify(response.data.response.user))
      localStorage.setItem(
        'userToken',
        JSON.stringify(response.data.response.token)
      )

      console.log(JSON.stringify(response.data.response.token))

      if (response.data.response.user.role === 'COMPANY') {
        navigate('/search')
      } else if (response.data.response.user.role === 'ADMIN') {
        navigate('/transactions')
      } else {
        navigate('/provider')
      }
    } catch (error) {
      console.log(error)
      let errorMessage

      switch (error.response?.data.error) {
        case 'User not found':
          errorMessage = 'Usuário não encontrado!'
          break
        case 'Unauthorized - Invalid password':
          errorMessage = 'Senha incorreta!'
          break
        default:
          errorMessage = 'Erro ao logar, tente novamente mais tarde!'
          break
      }

      setError('form', {
        type: 'manual',
        message: errorMessage
      })
    }
  }

  return (
    <AppLayout>
      <Helmet>
        <title>Entrar | Portal de Transparência de Carbono</title>
        <meta name="description" content="Entrar" />
      </Helmet>
      <Container fluid>
      <Row>
        <Col md="5 p-1">
<Box
className="p-3 p-sm-5"
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 6,
          background:
            'linear-gradient(111.9deg, rgba(151, 71, 255, 0.6) 0.07%, rgba(22, 24, 26, 0.24) 95.84%)',
          boxShadow: '-3px 11px 41px rgba(0, 0, 0, 0.05)'
        }}
      >
        <Box>
          <Typography
            fontWeight="600"
            color="#FFFFFF"
            sx={{
              fontSize: { xs: 26, xl: 48 },
              marginBottom: 0.5
            }}
          >
            Login
          </Typography>
          <Typography
            component="h4"
            color="#FFFFFF"
            sx={{ fontSize: { xs: 16, xl: 24 } }}
          >
            Por favor, informa seus dados abaixo
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingY: 3,
            gap: { xs: 3, xl: 5 }
          }}
        >
          <Box>
            <Typography
              color="#FFFFFF"
              marginBottom={0.4}
              sx={{
                fontSize: { xs: 14, xl: 21 },
                fontWeight: { xs: '500' },
                letterSpacing: { xl: 1 }
              }}
            >
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { value }, fieldState }) => (
                <Field
                  placeholder="Digite seu email"
                  helperText={fieldState.error?.message}
                  error={!!errors.email}
                  value={value}
                  id="email"
                  name="name"
                  autoComplete="Email"
                  {...register('email')}
                />
              )}
            />
          </Box>
          <Box>
            <Typography
              color="#FFFFFF"
              marginBottom={0.4}
              sx={{
                fontSize: { xs: 14, xl: 21 },
                fontWeight: { xs: '500' },
                letterSpacing: { xl: 1 }
              }}
            >
              Senha
            </Typography>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { value }, fieldState }) => (
                <Field
                  placeholder="Digite sua senha"
                  helperText={fieldState.error?.message}
                  error={!!errors.password}
                  value={value}
                  id="password"
                  name="password"
                  autoComplete="Senha"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Box>
          <CustomButton
            onClick={handleSubmit(handleLogin)}
            sx={{
              height: { xl: '64px' },
              fontSize: { xl: '24px' }
            }}
          >
            Entrar
          </CustomButton>

          {errors?.form && (
            <Typography
              color=" rgb(255, 65, 65)"
              textAlign={'center'}
              letterSpacing={0.8}
              sx={{
                fontSize: { xs: 14, xl: 21 }
              }}
            >
              {errors?.form.message}
            </Typography>
          )}
          <Link
            href="/recover_password"
            fontWeight="500"
            color="#FFFFFF"
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: { xs: 14, xl: 21 },
              letterSpacing: { xl: 0.7 }
            }}
          >
            Esqueci minha senha
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#FFFFFF',
            gap: 1,
            fontSize: { xs: 12, xl: 20 },
            marginBottom: { xs: 2, xl: 4 }
          }}
        >
          <Divider
            sx={{
              backgroundColor: '#FFFFFF',
              width: '46%',
              height: '0.8px'
            }}
          />
          ou
          <Divider
            sx={{
              backgroundColor: '#FFFFFF',
              width: '47%',
              height: '0.8px'
            }}
          />
        </Box>
        <CustomButton
          secondarycolor="#BDFEA4"
          onClick={handleNavigation}
          sx={{
            height: { xl: '64px' },
            fontSize: { xl: '24px' }
          }}
        >
          Cadastre-se
        </CustomButton>
      </Box>
        </Col>
        <Col md="6">
<Box className="d-none d-md-block float-right"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: '10rem',
        }}
      >
        <Typography
          textAlign="end"
          component="h1"
          fontWeight="bold"
          color="#FFFFFF"
          sx={{
            fontSize: { xs: 50, xl: 80 }
          }}
        >
          Conexões verdes para um mundo mais{' '}
          <span className="degrade">{words[currentWordIndex]}</span>
        </Typography>
      </Box>
</Col>
      </Row>

      

      

      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          paddingRight: 5,
          marginTop: -6,
          justifyContent: "right",
        }}
      >
        <img
          src={"logo-purple.svg"}
          style={{
            width: "144px",
          }}
        /> 
      </Box>
        */}
      </Container>

    </AppLayout>
  )
}
