import {
  Box,
  Divider,
  IconButton,
  Paper,
  Typography,
  Link
} from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import Arvore from '../assets/arvore.png'
import { useEffect, useState } from 'react'
import Green from '../assets/green.svg'
import { CustomButton } from '../styles'
import backgroundImage from '../assets/background.png'

import {
  Add as AddIcon,
  CallMade,
  East,
  ParkOutlined,
  RemoveRedEyeOutlined
} from '@mui/icons-material'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Col, Container, Row } from 'react-bootstrap'

const Transaction = () => {
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')
  const user = localStorage.getItem('user')
  const userLogado = JSON.parse(user)
  const [fornecedorList, setFornecedorList] = useState([])
  const [creditAmount, setCreditAmount] = useState(0)

  const userToken = localStorage.getItem('userToken')
  const token = JSON.parse(userToken)

  const fetchFornecedorList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/suppliers/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setFornecedorList(response.data.landPlotList)
      console.log(response.data.landPlotList)
      console.log('teste', response)
    } catch (error) {
      console.error(error)
    }
  }
  const [tokenList, setTokenList] = useState([])

  useEffect(() => {
    fetchFornecedorList()
  }, [])

  const fetchTokenList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/tokens/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setTokenList(response.data.response)
      setCreditAmount(response.data.response.creditBalance.ownedAmount)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTokenList()
  }, [])

  const handleLotListing = async (lotId) => {
    setLoadingTitle('Criando anúncio do lote...')
    setRequisitionLoading(true)
    try {
      const response = await axios.put(
        `https://dev-back.itcarbon.org/api/suppliers/land-plot/${lotId}/listing`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Sucesso!',
        'Anúncio do lote criado com sucesso',
        'success'
      ).then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Ocorreu algum erro!', 'error')
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: loadingTitle,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  const handleLandPlotStatus = (status, lotId) => {
    let buttonText, disabledStatus
    switch (status) {
      case 'APPROVED':
        buttonText = 'Anunciar lote'
        disabledStatus = false
        break

      case 'REJECTED':
        buttonText = 'Rejeitado'
        disabledStatus = true
        break

      case 'SOLD':
        buttonText = 'Vendido'
        disabledStatus = true
        break

      case 'LISTING':
        buttonText = 'Anunciado'
        disabledStatus = true
        break

      default:
        buttonText = 'Pendente'
        disabledStatus = true
        break
    }

    return (
      <Box>
        <CustomButton
          sx={{
            width: 150,
            margin: 'auto',
            height: 35,
            marginTop: '1.5rem'
          }}
          onClick={() => handleLotListing(lotId)}
          disabled={disabledStatus}
        >
          {buttonText}
        </CustomButton>
      </Box>
    )
  }

  return (
    <>
      <DashboardLayout>
        <Container>
          <div className="mt-5 pt-3">
            <Typography fontWeight="600" color="#2F2D2B" fontSize={28}>
              Olá, {userLogado.username}!
            </Typography>

            <Typography fontWeight="400" color="#848484" fontSize={20}>
              Gerencie seus fundos de emissão e venda de carbono.
            </Typography>
          </div>

          <Row className="mt-4">
            <Col lg="8 mt-3">
              <Paper
                style={{
                  width: '100%',
                  height: '30rem',
                  overflow: 'auto',
                  padding: 16
                }}
              >
                {fornecedorList.map((list, index) => (
                  <Box key={index}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <img
                          src={list.logoSupplier ? list.logoSupplier : Arvore}
                          alt="Imagem do Lote de Terra"
                          style={{
                            maxHeight: '10rem',
                            maxWidth: '10rem',
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                          width={200}
                        ></img>
                        <Box sx={{ margin: '1rem' }}>
                          <Typography
                            fontWeight="600"
                            color="#2F2D2B"
                            fontSize={20}
                          >
                            {list.name}
                          </Typography>
                          <Typography
                            fontWeight="500"
                            color="#2F2D2B"
                            fontSize={15}
                            sx={{ paddingTop: '0.3rem', paddingBottom: '1rem' }}
                          >
                            {list.description}
                          </Typography>
                          <Typography
                            fontWeight="400"
                            color="#31343B"
                            fontSize={18}
                          >
                            {list.area}m²
                          </Typography>
                          <Box sx={{ display: 'flex', marginTop: '0.5rem' }}>
                            <img src={Green} width={25}></img>
                            {list.value} TPC | {list.value * 100} BRL
                          </Box>
                        </Box>
                      </Box>

                      {handleLandPlotStatus(list.status, list.id)}
                    </Box>

                    <Divider></Divider>
                  </Box>
                ))}
              </Paper>
            </Col>
            <Col lg="4 mt-3">
              <Box
                sx={{
                  marginTop: '0rem',
                  marginBottom: '2rem',
                  backgroundColor: '#272727',
                  padding: '1rem',
                  borderRadius: '20px',
                  // backgroundImage: `url(${backgroundImage})`,
                  backgroundPosition: 'right   bottom',
                  backgroundSize: '350px',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '24rem'
                }}
              >
                <Box
                  sx={{
                    // width: '20rem',
                    backgroundColor: '#272727',
                    borderRadius: '30px'
                  }}
                >
                  <Typography
                    fontWeight="500"
                    color={'#B8B8B8'}
                    fontSize={28}
                    sx={{ paddingTop: '1.5rem' }}
                  >
                    Sua Carteira
                  </Typography>
                  <Typography
                    fontWeight="500"
                    color={'#FFFFFF'}
                    sx={{
                      marginTop: '0.5rem',
                      cursor: 'pointer',
                      marginBottom: '0.3rem',
                      fontSize: { xs: '20px', xl: '20px', lg: '20px' },
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all'
                    }}
                  >
                    <Link
                      href={`https://mumbai.polygonscan.com/address/${tokenList?.walletAddress}#tokentxns`}
                      target="_blank"
                      underline="none"
                      sx={{
                        color: '#FFFFFF',
                        textDecoration: 'none', // Remove underline
                        '&:hover': {
                          color: '#B8B8B8'
                        }
                      }}
                    >
                      {tokenList?.walletAddress}↗
                    </Link>
                  </Typography>

                  <Typography fontWeight="500" color={'#B8B8B8'} fontSize={28}>
                    Seus Créditos
                  </Typography>
                  <Typography
                    fontWeight="500"
                    color={'#FFFFFF'}
                    sx={{ marginTop: '0.5rem' }}
                    fontSize={42}
                  >
                    {creditAmount}
                    <Typography
                      fontWeight="500"
                      color={'#B8B8B8'}
                      fontSize={24}
                      component={'span'}
                    >
                      {' '}
                      BRL
                    </Typography>
                  </Typography>
                  <Typography
                    fontWeight="500"
                    color={'#FFFFFF'}
                    sx={{ marginTop: '0.5rem' }}
                    fontSize={42}
                  >
                    {tokenList?.tpc?.quantity ? tokenList.tpc.quantity : 0}
                    <Typography
                      fontWeight="500"
                      color={'#B8B8B8'}
                      fontSize={24}
                      component={'span'}
                    >
                      {' '}
                      TPC
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </DashboardLayout>
    </>
  )
}

export default Transaction
