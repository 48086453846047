import { Box, Link, styled, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import Footer from '../components/footer'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MenuNav } from '../components/navbar'
import { CallMade, East, RemoveRedEyeOutlined } from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import TransactionTable from '../components/table'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Empresa3 from '../assets/empresa3.svg'
import diamanteazul from '../assets/diamanteazul.svg'
import diamanterosa from '../assets/diamanterosa.svg'
import graficPNG from '../assets/grafic.png'
import { Card, Col, Container, Row } from 'react-bootstrap';

const StyledTable = styled(Table)({
  '& td': {
    borderBottom: 'none'
  }
})

export default function CompanyPublic() {
  let navigate = useNavigate()
  const { slug } = useParams()

  const [companyInfo, setCompanyInfo] = useState({
    company: { createdAt: '' },
    tokens: {}
  })

  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/companies/public-info?slug=${slug}`
      )
      console.log(response.data.response)
      setCompanyInfo(response.data.response)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  function converterParaData(data) {
    const dataObjeto = new Date(data)
    const options = { timeZone: 'America/Sao_Paulo' }
    const dataBrasileira = dataObjeto.toLocaleDateString('pt-BR', options)
    return dataBrasileira
  }

  console.log(companyInfo)

  const data = [
    {
      name: 'TCC',
      total: companyInfo.tokens && companyInfo.tokens.tcc ? companyInfo.tokens.tcc.quantity : 0,
      fill: '#9747FF'
    },
    {
      name: 'TDC',
      total: companyInfo.tokens && companyInfo.tokens.tdc ? companyInfo.tokens.tdc.quantity : 0,
      fill: '#E25A71'
    }
  ];
  

  return (
    <>
      <Container className='p-0' fluid style={
        {
          background:
          'radial-gradient(circle at 120% 70%, rgba(189, 254, 164, 0.25) 0%, transparent 30%) no-repeat,radial-gradient(circle at -18% 100%, rgba(151, 71, 255,0.25) 11%, transparent 27%) no-repeat 100% 0, radial-gradient(circle at -18% 30%, rgba(151, 71, 255,0.25) 9%, transparent 24%) no-repeat 100% 0, radial-gradient(circle at -15% 54%, rgba(189, 254, 164, 0.25) 9%, transparent 24%) no-repeat 100% 0, radial-gradient(circle at 110% 14%, rgba(151, 71, 255,0.25) 9%, transparent 20%) no-repeat 100% 0,  radial-gradient(circle at 68% -24%, rgba(189, 254, 164, 0.25) 14%, transparent 44%) no-repeat 100% 0,#16181A'
        }
      }>
        <MenuNav/>
        <div className='p-2'>

          <Container style={{borderRadius: '20px'}} className='bg-white p-3'>
            <Row>
                <Col sm="6">
                  <div className='d-flex'>
                      <div>
                      <img
                  src={Empresa3}
                  style={{
                    width: '131px'
                  }}
                />
                      </div>
                      <div className='pt-3'>
                      <h1
                    style={{
                      fontWeight: '700'
                    }}
                  >
                    {loading ? <>Carregando</> : companyInfo.company.legalName}
                  </h1>
                  <h6
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {loading ? (
                      <>Carregando</>
                    ) : (
                      companyInfo.company.description
                    )}
                  </h6>
                      </div>
                  </div>
                </Col>
                <Col sm="6">
                <Col sm="4" className='float-end d-none d-md-block'>
                  <div>
                  <h6
                      style={{
                        fontSize: '18px',
                        fontWeight: '500'
                      }}
                    >
                      Ingressou em
                    </h6>
                    <h6
                      style={{
                        fontSize: '15px',
                        color: '#31343B'
                      }}
                    >
                      {converterParaData(companyInfo.company.createdAt)}
                    </h6>
                  </div>
                  <div>
                    <h6
                      style={{
                        marginTop: 2,
                        fontSize: '18px',
                        fontWeight: '500'
                      }}
                    >
                      Conquistas
                    </h6>
                    <h6
                      style={{
                        fontSize: '15px',
                        color: '#31343B'
                      }}
                    >
                      <img src={diamanterosa} /> <img src={diamanteazul} />
                    </h6>
                  </div>
                  </Col>
                </Col>
            </Row>
            <Row className='mt-5'>
              <Col sm="6">
                <Card className='mb-3 p-3'>
                <h6>
                        Crédito de carbono
                      </h6>
                      <span
                        style={{
                          color: '#2F2D2B',
                          fontWeight: '500',
                          fontSize: '18px'
                        }}
                      >
                        {companyInfo.tokens.tcc
                          ? companyInfo.tokens.tcc.quantity
                          : 0}{' '}
                        {companyInfo.tokens.tcc
                          ? companyInfo.tokens.tcc.type
                          : 'TCC'}
                      </span>
                </Card>
                <Card className='mb-3 p-3'>
                <h6>
                        Débito de carbono
                      </h6>
                      <span
                        style={{
                          color: '#2F2D2B',
                          fontWeight: '500',
                          fontSize: '18px'
                        }}
                      >
                        {companyInfo.tokens.tdc
                          ? companyInfo.tokens.tdc.quantity
                          : 0}{' '}
                        {companyInfo.tokens.tdc
                          ? companyInfo.tokens.tdc.type
                          : 'TDC'}
                      </span>
                </Card>
              </Col>
              <Col sm="4">

                                    <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="total" />
                    </BarChart>
                    </ResponsiveContainer>
            
              </Col>
            </Row>
            <Col>
            <TransactionTable slug={slug} />

            </Col>
          </Container>
        </div>

          <Footer/>
        </Container>   
    </>

  )
}
