import React, { useEffect, useState } from "react";

import CustomCard from "./customCard"; // Componente de card personalizado
import { Box, Button, Grid, Typography } from "@mui/material";
import { CustomButton, Field } from "../../styles";
import { ArrowDownward, ArrowUpward, Search } from "@mui/icons-material";
import axios from "axios";

const CardList = ({ search, title }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const fetchData = async () => {
    try {
      let url = "https://dev-back.itcarbon.org/api/companies/list?search=";

      if (searchTerm) {
        url += `${searchTerm}`;
      }

      const response = await axios.post(url);
      setSearchResults(response.data.response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const cardsPerPage = search ? 9 : 5;
  const [showAllCards, setShowAllCards] = useState(false);

  const handleShowMore = () => {
    setShowAllCards(!showAllCards);
  };
  console.log(searchResults);

  const renderCards = () => {
    if (showAllCards) {
      return searchResults.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomCard title={card.legalName} slug={card.slug} />
        </Grid>
      ));
    } else {
      const limitedCards = searchResults.slice(0, cardsPerPage);
      return limitedCards.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomCard title={card.legalName} slug={card.slug} />
        </Grid>
      ));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: 6,
      }}
    >
      <h1
      className="display-5 text-center"
        style={{
          color: "#FFFFFF",
          fontWeight: "700",
        }}
      >
        {title}
      </h1>
      {search && (
        <Box sx={{ width: "100%" }}>
          <Field
            value={searchTerm}
            onChange={handleChange}
            placeholder="Pesquise aqui"
            sx={{
              height: "50px",
              borderRadius: 10,
              "& .MuiInputBase-root": {
                color: "#A0A0A0",
                border: "0.2px solid #A0A0A0",
                borderRadius: 10,
                backgroundColor: "transparent",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "14px",
                color: "#A0A0A0",
                opacity: 1,
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  border: "0.1px solid #9747FF",
                  boxShadow: "0 0 10px 0 rgba(151, 71, 255, 0.7)",
                },
                "&.Mui-focused fieldset": {
                  border: "0.1px solid #9747FF",
                  boxShadow: "0 0 30px 0 rgba(151, 71, 255, 0.7)",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <Search
                  sx={{ width: "22px", color: "#A0A0A0", marginRight: 1 }}
                />
              ),
              endAdornment: (
                <CustomButton
                  onClick={handleChange}
                  sx={{ width: "168px", fontSize: "14px" }}
                >
                  Buscar
                </CustomButton>
              ),
            }}
          />
        </Box>
      )}
      <Grid
        container
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {renderCards()}
      </Grid>
      {search ? (
        <Box
          onClick={handleShowMore}
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: -6,
            color: "#BDFEA4",
            fontSize: { xs: 11, xl: 16 },
            fontWeight: "500",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {showAllCards ? "Exibir menos" : "Exibir mais"}
          {showAllCards ? (
            <ArrowUpward
              sx={{ width: { xs: "12px", xl: "18px" }, marginLeft: 0.4 }}
            />
          ) : (
            <ArrowDownward
              sx={{ width: { xs: "12px", xl: "18px" }, marginLeft: 0.4 }}
            />
          )}
        </Box>
      ) : (
        <CustomButton
          onClick={handleShowMore}
          secondarycolor={"#BDFEA4"}
          sx={{
            marginTop: -4,
            fontWeight: "700",
            width: { xs: "180px", xl: "204px" },
            height: { xs: "37px", xl: "44px" },
            fontSize: { xs: 11, xl: 16 },
          }}
        >
          {showAllCards ? "Mostrar menos" : "Ver lista completa"}
        </CustomButton>
      )}
    </Box>
  );
};

export default CardList;
