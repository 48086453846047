import { Box, Link, styled, Typography } from '@mui/material'
import { CustomButton, Field } from '../styles'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import {
  CallMade,
  East,
  RemoveRedEyeOutlined,
  Token
} from '@mui/icons-material'
// import Head from "next/head";
import TransactionTable from '../components/table'
import Vetor from '../assets/vetor.svg'
import * as yup from 'yup'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'
import s3 from '../helpers/aws.config'
import { removeCnpjMask } from '../helpers/getCnpjMask'
import { Col, Container, Row } from 'react-bootstrap'


const formValidation = yup.object({
  cnpj: yup.string().required('O CNPJ é obrigatório'),
  corporateName: yup.string().required('A razão Social é obrigatória'),
  fantasyName: yup.string().required('O nome fantasia é obrigatório'),
  address: yup.string().required('O endereço é obrigatório'),
  number: yup
    .number()
    .typeError('O número é obrigatório')
    .required('O número é obrigatório'),
  complement: yup.string(),
  neighborhood: yup.string().required('O bairro é obrigatório'),
  state: yup.string().required('O estado é obrigatório'),
  city: yup.string().required('A cidade é obrigatória')
})

const Dashboard = () => {
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')
  const userToken = JSON.parse(localStorage.getItem('userToken'))
  const user = JSON.parse(localStorage.getItem('user'))
  const [tokensOwned, setTokensOwned] = useState({})
  const [companyInfo, setCompanyInfo] = useState()
  const [companyLogo, setCompanyLogo] = useState('')

  const fetchTokensOwned = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/tokens/`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      )

      setTokensOwned(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCompanyInfo = async () => {
    try {
      const response = await axios.post(
        'https://dev-back.itcarbon.org/api/companies/list?search=',
        {
          companyId: user.companyId
        }
      )

      setCompanyLogo(response.data.response[0].logoCompany)
      setCompanyInfo(response.data.response[0])
      setValue('cnpj', response.data.response[0].cnpj)
      setValue('address', response.data.response[0].address)
      setValue('city', response.data.response[0].city)
      setValue('complement', response.data.response[0].addressLine2)
      setValue('corporateName', response.data.response[0].legalName)
      setValue('fantasyName', response.data.response[0].brandName)
      setValue('neighborhood', response.data.response[0].neighborhood)
      setValue('number', response.data.response[0].streetNumber)
      setValue('state', response.data.response[0].state)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTokensOwned()
    fetchCompanyInfo()
  }, [])

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidation)
  })

  const getCnpjMask = (value) => {
    return value
      ?.replace(/\D/g, '') // remove qualquer caracter que não seja número
      ?.replace(
        /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/,
        (match, p1, p2, p3, p4, p5) => {
          let formatted = ''
          if (p2 === undefined) {
            formatted = p1
          } else if (p3 === undefined) {
            formatted = `${p1}.${p2}`
          } else if (p4 === undefined) {
            formatted = `${p1}.${p2}.${p3}`
          } else if (p5 === undefined) {
            formatted = `${p1}.${p2}.${p3}/${p4}`
          } else {
            formatted = `${p1}.${p2}.${p3}/${p4}-${p5}`
          }
          return formatted
        }
      )
      .substr(0, 18) // limita o tamanho do CNPJ em 18 caracteres
  }

  function convertToNextJSFormat(data) {
    // Remover espaços em branco
    const step1 = data?.replace(/\s/g, '')

    // Converter para letras minúsculas
    const step2 = step1?.toLowerCase()

    // Substituir caracteres especiais e acentos por underscores
    const step3 = step2?.replace(/[^a-z0-9]/g, '_')

    return step3
  }
  let navigate = useNavigate()

  console.log('usuario', user.companyId)

  const onFinish = async (data) => {
    setLoadingTitle('Atualizando dados da empresa, aguarde...')
    setRequisitionLoading(true)
    try {
      const response = await axios.put(
        `https://dev-back.itcarbon.org/api/companies/update/${user.companyId}`,
        {
          cnpj: data.cnpj,
          address: data.address,
          city: data.city,
          state: data.state,
          streetNumber: data.number,
          neighborhood: data.neighborhood,
          addressLine2: data.complement,
          legalName: data.corporateName,
          brandName: data.fantasyName,
          slug: convertToNextJSFormat(data.corporateName)
        },
        {
          headers: {
            apiauth:
              'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlTZWNyZXQiOiJjNDY0ZGVlODBkMGNlNTYwMmQ3OTI1ZmY5ZGEzYmUzZWY3Y2E5MzFiNTQ2YTk2MGY1ZGM2NDQ0MjMzZWUzYmNjIn0.S4qdBBlWDyaPRjsTUZUb_zbqHpdz4wTYKyAe35ILRSs',
            Authorization: `Bearer ${userToken}`
          }
        }
      )
      console.log('dados', response)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Sucesso!',
        'Dados da empresa atualizados com sucesso!',
        'success'
      ).then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Erro ao atualizar dados!', 'error').then(() => {
        window.location.reload()
      })
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: loadingTitle,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  const handleUploadFile = (file) => {
    const cnpjOnlyNumbers = removeCnpjMask(companyInfo.cnpj)
    const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)

    const params = {
      Bucket: 'statics-pct',
      Key: `${cnpjOnlyNumbers}.${fileExtension}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read'
    }

    s3.upload(params, async (error, data) => {
      const formData = getValues()
      if (error) {
        console.log(error)
      } else {
        console.log('File uploaded successfully!', data.Location)

        await axios.put(
          `https://dev-back.itcarbon.org/api/companies/update/${user.companyId}`,
          {
            cnpj: formData.cnpj,
            address: formData.address,
            city: formData.city,
            state: formData.state,
            streetNumber: formData.number,
            neighborhood: formData.neighborhood,
            addressLine2: formData.complement,
            legalName: formData.corporateName,
            brandName: formData.fantasyName,
            slug: convertToNextJSFormat(formData.corporateName),
            logoCompany: await data?.Location
          },
          {
            headers: {
              apiauth:
                'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlTZWNyZXQiOiJjNDY0ZGVlODBkMGNlNTYwMmQ3OTI1ZmY5ZGEzYmUzZWY3Y2E5MzFiNTQ2YTk2MGY1ZGM2NDQ0MjMzZWUzYmNjIn0.S4qdBBlWDyaPRjsTUZUb_zbqHpdz4wTYKyAe35ILRSs',
              Authorization: `Bearer ${userToken}`
            }
          }
        )
        setCompanyLogo(await data?.Location)
      }
    })
  }

  return (
    <DashboardLayout email={user.email} name={user.username} role={user.role}>
      <Helmet>
        <title>Perfil Público | Portal de Transparência de Carbono</title>
        <meta name="description" content="Perfil Público" />
      </Helmet>
    <Container className="bg-white mt-3 p-4 rounded">
    <Typography
              fontWeight="600"
              color="#2F2D2B"
              className='pt-3'
              sx={{
                fontSize: { xs: 26, xl: 48 },
                marginBottom: { xs: 0.5, xl: 1 }
              }}
            >
              Perfil Público
            </Typography>
      <Row>
        <Col lg="6 mt-3">
          <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}
            >
              <Box>
                <Typography
                  color="black"
                  marginBottom={0.2}
                  sx={{
                    fontSize: { xs: 14, xl: 21 },
                    fontWeight: { xs: '500' },
                    letterSpacing: { xl: 0.7 }
                  }}
                >
                  CNPJ
                </Typography>

                <Controller
                  name="cnpj"
                  control={control}
                  defaultValue=""
                  render={({ field: { value }, fieldState }) => (
                    <Field
                      placeholder={'00.000.000/0000-00'}
                      helperText={fieldState.error?.message}
                      error={!!errors.cnpj}
                      value={getCnpjMask(value)}
                      id="cnpj"
                      name="cnpj"
                      autoComplete="CNPJ"
                      inputProps={{ maxLength: 18 }}
                      {...register('cnpj')}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography
                  color="black"
                  marginBottom={0.2}
                  sx={{
                    fontSize: { xs: 14, xl: 21 },
                    fontWeight: { xs: '500' },
                    letterSpacing: { xl: 0.7 }
                  }}
                >
                  Razão Social
                </Typography>
                {console.log(companyInfo)}

                <Controller
                  name="corporateName"
                  control={control}
                  defaultValue={companyInfo?.description ?? ''}
                  render={({ field: { value }, fieldState }) => (
                    <Field
                      placeholder="Empresa Legal LTDA"
                      helperText={fieldState.error?.message}
                      error={!!errors.corporateName}
                      value={value}
                      defaultValue={companyInfo?.description ?? ''}
                      id="corporateName"
                      name="corporateName"
                      autoComplete="Razão Social"
                      {...register('corporateName')}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography
                  color="black"
                  marginBottom={0.2}
                  sx={{
                    fontSize: { xs: 14, xl: 21 },
                    fontWeight: { xs: '500' },
                    letterSpacing: { xl: 0.7 }
                  }}
                >
                  Nome fantasia
                </Typography>
                <Controller
                  name="fantasyName"
                  control={control}
                  defaultValue=""
                  render={({ field: { value }, fieldState }) => (
                    <Field
                      placeholder="Empresa Legal"
                      helperText={fieldState.error?.message}
                      error={!!errors.fantasyName}
                      value={value}
                      id="fantasyName"
                      name="fantasyName"
                      autoComplete="Nome fantasia"
                      {...register('fantasyName')}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography
                  color="black"
                  marginBottom={0.2}
                  sx={{
                    fontSize: { xs: 14, xl: 21 },
                    fontWeight: { xs: '500' },
                    letterSpacing: { xl: 0.7 }
                  }}
                >
                  Endereço
                </Typography>
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  render={({ field: { value }, fieldState }) => (
                    <Field
                      placeholder="R. Jorge Amado "
                      helperText={fieldState.error?.message}
                      error={!!errors.address}
                      value={value}
                      id="address"
                      name="address"
                      autoComplete="Endereço"
                      {...register('address')}
                    />
                  )}
                />
              </Box>

              <Box sx={{ display: 'flex', gap: 1.5 }}>
                <Box flex={0.2}>
                  <Typography
                    color="black"
                    marginBottom={0.2}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Número
                  </Typography>
                  <Controller
                    name="number"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="158"
                        helperText={fieldState.error?.message}
                        error={!!errors.number}
                        value={value}
                        id="number"
                        name="number"
                        autoComplete="Número"
                        {...register('number')}
                      />
                    )}
                  />
                </Box>

                <Box flex={0.3}>
                  <Typography
                    color="black"
                    marginBottom={0.2}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Complemento
                  </Typography>
                  <Controller
                    name="complement"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Apto 200"
                        helperText={fieldState.error?.message}
                        error={!!errors.complement}
                        value={value}
                        id="complement"
                        name="complement"
                        autoComplete="Complemento"
                        {...register('complement')}
                      />
                    )}
                  />
                </Box>
                <Box flex={0.5}>
                  <Typography
                    color="black"
                    marginBottom={0.2}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Bairro
                  </Typography>
                  <Controller
                    name="neighborhood"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Centro"
                        helperText={fieldState.error?.message}
                        error={!!errors.neighborhood}
                        value={value}
                        id="neighborhood"
                        name="neighborhood"
                        autoComplete="Bairro"
                        {...register('neighborhood')}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 1.5 }}>
                <Box flex={0.5}>
                  <Typography
                    color="black"
                    marginBottom={0.2}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Estado
                  </Typography>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="SP"
                        helperText={fieldState.error?.message}
                        error={!!errors.state}
                        value={value}
                        id="state"
                        name="state"
                        autoComplete="Estado"
                        {...register('state')}
                      />
                    )}
                  />
                </Box>
                <Box flex={0.5}>
                  <Typography
                    color="black"
                    marginBottom={0.2}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Cidade
                  </Typography>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="São Paulo"
                        helperText={fieldState.error?.message}
                        error={!!errors.city}
                        value={value}
                        id="city"
                        name="city"
                        autoComplete="Cidade"
                        {...register('city')}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
        </Col>
        <Col lg="6 mt-3">
          <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 3
              }}
            >
              {/* Segunda parte */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 0.4,
                }}
              >
                <img
                  src={companyLogo ? companyLogo : Vetor}
                  alt="imagem"
                  width={150}
                  height={150}
                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                ></img>
                {/* Editar aqui */}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <label htmlFor="logoInput">
                  <CustomButton
                    sx={{
                      width: 150,
                      margin: 'auto',
                      height: 35,
                      position: 'relative',
                      overflow: 'hidden',
                      display: 'inline-block',
                      cursor: 'pointer'
                    }}
                  >
                    Alterar logo
                    <input
                      type="file"
                      accept="image/*"
                      id="logoInput"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer'
                      }}
                      onChange={(event) =>
                        handleUploadFile(event.target.files[0])
                      }
                    />
                  </CustomButton>
                </label>
              </Box>

              <Box>
                <Typography
                  color={'#2F2D2B'}
                  fontWeight={'500'}
                  sx={{ fontSize: { xs: 16, xl: 24 } }}
                >
                  {companyInfo?.legalName ?? 0}{' '}
                </Typography>
                <Typography
                  color={'#636363'}
                  sx={{
                    fontSize: { xs: 12, xl: 18 }
                  }}
                >
                  {companyInfo?.brandName ?? 0}{' '}
                </Typography>

                {/* 
                <Link
                  href="http://google.com"
                  color={'#9747FF'}
                  fontWeight={'500'}
                  sx={{
                    fontSize: { xs: 14, xl: 21 }
                  }}
                >
                  Ver mais
                </Link> */}
              </Box>

              <Box
                sx={{
                  border: '1px solid #C6C6C6',
                  borderRadius: 2,
                  padding: '1.2rem',
                  justifyContent: 'space-between',
                  display: 'flex'
                }}
              >
                <Box>
                  <Typography
                    color={'#848484'}
                    fontWeight={'200'}
                    sx={{
                      fontSize: { xs: 12, xl: 20 }
                    }}
                  >
                    Crédito de carbono
                  </Typography>
                  <Typography
                    sx={{
                      color: '#2F2D2B',
                      fontWeight: '500',
                      fontSize: { xs: 18, xl: 24 }
                    }}
                  >
                    {tokensOwned.tcc ? tokensOwned.tcc.quantity : '0 '} TCC
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.4,
                      paddingBottom: '1rem',
                      marginTop: { xs: -0.6, xl: -0.3 }
                    }}
                  ></Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    color={'#9747FF'}
                    fontWeight={'500'}
                    sx={{ fontSize: { xs: 14, xl: 21 } }}
                  >
                    Detalhes
                  </Link>
                  <East
                    sx={{ width: { xs: '15px', xl: '22px' }, color: '#9747FF' }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  border: '1px solid #C6C6C6',
                  borderRadius: 2,
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography
                    color={'#848484'}
                    fontWeight={'200'}
                    sx={{ fontSize: { xs: 12, xl: 20 } }}
                  >
                    Débito de carbono
                  </Typography>
                  <Typography
                    sx={{
                      color: '#2F2D2B',
                      fontWeight: '500',
                      fontSize: { xs: 18, xl: 24 }
                    }}
                  >
                    {tokensOwned.tdc ? tokensOwned.tdc.quantity : '0 '} TDC
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.4,
                      paddingBottom: '1rem',
                      marginTop: { xs: -0.6, xl: -0.3 }
                    }}
                  ></Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    color={'#9747FF'}
                    fontWeight={'500'}
                    sx={{ fontSize: { xs: 14, xl: 21 } }}
                  >
                    Detalhes
                  </Link>
                  <East
                    sx={{ width: { xs: '15px', xl: '22px' }, color: '#9747FF' }}
                  />
                </Box>
              </Box>
            </Box>
        </Col>
      </Row>
      <div>
        
      <CustomButton
          onClick={handleSubmit(onFinish)}
          className='mt-3 mb-3'
          sx={{
            height: { xl: '50px' },
            fontSize: { xl: '24px' }
          }}
        >
          Salvar
        </CustomButton>
      </div>
      <div>
      <TransactionTable userId={user.id} />

        </div>
    </Container>
     
    </DashboardLayout>
  )
}

export default Dashboard
