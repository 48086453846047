const Ground = () => {

    return (

<>
<h1>Em breve, aqui estarão as informações do terreno que gerou esses TPC</h1>
</>
    )

}

export default Ground