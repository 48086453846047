import { AppBar, Box, Button, Divider, Link, Typography } from '@mui/material'

import { ArrowForward, East, SearchOutlined } from '@mui/icons-material'
import { CustomButton } from '../styles'
import Footer from '../components/footer'
import '../app/globals.css'
// import Head from "next/head";
import CardList from '../components/cardList'
import { MenuNav } from '../components/navbar'
import { Helmet } from 'react-helmet'
import { Col } from 'react-bootstrap'

const SearchCompanies = () => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        background:
          'radial-gradient(circle at -18% 90%, rgba(151, 71, 255,0.20) 9%, transparent 27%) no-repeat 100% 0, radial-gradient(circle at -18% 30%, rgba(151, 71, 255,0.20)  9%, transparent 27%) no-repeat 100% 0, radial-gradient(circle at 117% 57%, rgba(189, 254, 164, 0.20) 0%, transparent 27%) no-repeat 100% 0,  #16181A'
      }}
    >
      <Helmet>
        <title>Buscar por empresas | Portal de Transparência de Carbono</title>
        <meta name="description" content="Buscar por empresas" />
      </Helmet>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Box
          sx={{
            flex: '1 0 auto',
          }}
        >
          <MenuNav />
          <Box marginY={7}>
            <Col sm="5" className='mx-auto d-block p-3' >
            <CardList search title={'Encontre as empresas participantes'} />
            </Col>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  )
}

export default SearchCompanies
