import { Box, Divider, Link, styled, Typography } from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import { IconButton } from '@mui/material'
import Purple from '../assets/purple.svg'
import { DataGrid } from '@mui/x-data-grid'
import Vetor from '../assets/Vector.png'
import Green from '../assets/green.svg'
import Red from '../assets/red.svg'
import CoinPurple from '../assets/coin-purple.svg'
import CoinGreen from '../assets/coin-green.svg'
import CoinRed from '../assets/coin-red.svg'
import Edit from '../assets/edit.svg'
import backgroundImage from '../assets/background.png'

import { CustomButton, Field } from '../styles'
import { Pagination } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { ParkOutlined } from '@mui/icons-material'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
const Wallet = ({}) => {
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const [tokenList, setTokenList] = useState([])
  const [creditAmount, setCreditAmount] = useState(0)
  const [transactionList, setTransactionList] = useState([])

  const columns = [
    {
      field: 'token',
      headerName: 'Token',
      width: 150,
      renderCell: (params) => (
        <TableCell>
          {params.value === 'TCC' && (
            <div style={{ display: 'flex' }}>
              <div>
                <img src={Purple} alt="Purple" width="100%" />
              </div>
              <div>TCC</div>
            </div>
          )}
          {params.value === 'TPC' && (
            <div style={{ display: 'flex' }}>
              <div>
                <img src={Green} alt="Green" width="100%" />
              </div>
              <div>TPC</div>
            </div>
          )}
          {params.value === 'TDC' && (
            <div style={{ display: 'flex' }}>
              <div>
                <img src={Red} alt="Red" width="100%" />
              </div>
              <div>TDC</div>
            </div>
          )}
        </TableCell>
      )
    },
    { field: 'data', headerName: 'Data', width: 150 },
    { field: 'hash', headerName: 'Hash', width: 150 },
    { field: 'valor', headerName: 'Valor', width: 150 },
    {
      field: 'De',
      headerName: 'De',
      width: 500,
      renderCell: (params) => (
        <TableCell>
          {params.value && (
            <Link
              target="_blank"
              sx={{ color: '#6F7380' }}
              underline="none"
              href={`https://mumbai.polygonscan.com/address/${params.value}#tokentxns`}
            >
              {params.value}
            </Link>
          )}
        </TableCell>
      )
    },
    {
      field: 'Para',
      headerName: 'Para',
      width: 500,
      renderCell: (params) => (
        <TableCell>
          {params.value && (
            <Link
              target="_blank"
              sx={{ color: '#6F7380' }}
              underline="none"
              href={`https://mumbai.polygonscan.com/address/${params.value}#tokentxns`}
            >
              {params.value}
            </Link>
          )}
        </TableCell>
      )
    },
    {
      field: 'terreno',
      headerName: 'Visualizar terreno',
      width: 300,
      renderCell: (params) => {
        if (params.row.token === 'TPC') {
          return (
            <TableCell>
              <Link
                target="_blank"
                sx={{ color: '#6F7380' }}
                underline="none"
                href={`/grounds`}
              >
                Acessar terreno
              </Link>
            </TableCell>
          )
        }

        return <TableCell />
      }
    }
  ]
  const rows = transactionList.map((transaction, index) => ({
    id: transaction.id,
    data: converterParaData(transaction?.createdAt),
    token: transaction.transactionType,
    hash: '0xae90f3f9e974f53e04680f9054419e8f33f523ad9745d3ad2f2a2e5e3b7147a3',
    valor: transaction.amount,
    De: transaction.to.address,
    Para: transaction.from.address,
    terreno: transaction.transactionType
  }))
  console.log('teste', transactionList)

  const userToken = localStorage.getItem('userToken')
  const token = JSON.parse(userToken)

  const fetchTokenList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/tokens/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setTokenList(response.data.response)
      setCreditAmount(response.data.response.creditBalance.ownedAmount)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTransactionList = async () => {
    try {
      const response = await axios.post(
        `https://dev-back.itcarbon.org/api/tokens/transaction/`,
        { userId: user.id }
      )
      setTransactionList(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTransactionList()
  }, [])

  function converterParaData(data) {
    const dataObjeto = new Date(data)
    const options = { timeZone: 'America/Sao_Paulo' }
    const dataBrasileira = dataObjeto.toLocaleDateString('pt-BR', options)
    return dataBrasileira
  }

  useEffect(() => {
    fetchTokenList()
  }, [])

  const fetchConverter = async (data) => {
    setLoadingTitle('Solicitando a troca de tokens...')
    setRequisitionLoading(true)
    try {
      const response = await axios.post(
        'https://dev-back.itcarbon.org/api/tokens/trade-request/',
        {
          quantity: tokenList.tpc.quantity
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Sucesso!', 'Troca de tokens realizada com sucesso!', 'success')
      window.location.reload()
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Erro!',
        'A troca falhou. Aguarde alguns instantes e tente novamente.',
        'error'
      )
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: loadingTitle,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }
  const onAdd = async () => {
    try {
      const { value: valorString } = await Swal.fire({
        title: 'Qual o valor que deseja adicionar?',
        input: 'number',
        inputPlaceholder: 'O valor deve ser pelo menos 1',
        inputAttributes: {
          min: '1',
          max: '100000'
        }
      })

      var valor = parseFloat(valorString)

      const response = await axios.put(
        'https://dev-back.itcarbon.org/api/wallets/',
        {
          amount: valor,
          paidValue: valor * 10
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      console.log(response)

      setCreditAmount((prevCreditAmount) => prevCreditAmount + valor)
      Swal.fire(
        'Sucesso!',
        'Foram adicionados ' + valor + ' BRL na sua carteira!',
        'success'
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DashboardLayout email={user.email} name={user.username} role={user.role}>
      <Container className="mt-5">
        <Row>
          <Col lg="8">
            <Row>
              <Col sm="4 mt-5">
                <Card
                  className="text-center w-100 h-100"
                  style={{ backgroundColor: '#272727' }}
                >
                  <Card.Body>
                    <h2 className="text-white">
                      <div style={{ marginTop: '-3rem' }}>
                        <img
                          style={{
                            backgroundColor: '#272727',
                            padding: '15px',
                            borderRadius: '50px'
                          }}
                          src={Purple}
                          width={70}
                        ></img>
                      </div>
                      {tokenList.tcc ? tokenList.tcc.quantity : 0}{' '}
                      {tokenList.tcc ? tokenList.tcc.type : 'TCC'}
                    </h2>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="4 mt-5">
                <Card
                  className="text-center w-100 h-100 "
                  style={{ backgroundColor: '#272727' }}
                >
                  <Card.Body>
                    <h2 className="text-white">
                      <div style={{ marginTop: '-3rem' }}>
                        <img
                          style={{
                            backgroundColor: '#272727',
                            padding: '15px',
                            borderRadius: '50px'
                          }}
                          src={Green}
                          width={70}
                        ></img>
                      </div>
                      {tokenList.tdc ? tokenList.tdc.quantity : 0}{' '}
                      {tokenList.tdc ? tokenList.tdc.type : 'TDC'}
                    </h2>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="4 mt-5">
                <Card
                  className="text-center w-100 h-100"
                  style={{ backgroundColor: '#272727' }}
                >
                  <Card.Body>
                    <h2 className="text-white">
                      <div style={{ marginTop: '-3rem' }}>
                        <img
                          style={{
                            backgroundColor: '#272727',
                            padding: '15px',
                            borderRadius: '50px'
                          }}
                          src={Red}
                          width={70}
                        ></img>
                      </div>
                      <div>
                        {tokenList.tpc ? tokenList.tpc.quantity : 0}{' '}
                        {tokenList.tpc ? tokenList.tpc.type : 'TCC'}
                      </div>
                    </h2>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="5  h-100 mt-3">
                <Card className="text-center">
                  <Card.Body>
                    <img className="" src={Purple} width={60}></img>
                    <h6>
                      {tokenList.tpc ? tokenList.tpc.quantity : 0}{' '}
                      {tokenList.tpc ? tokenList.tpc.type : 'TCC'}
                    </h6>

                    <Button
                      onClick={fetchConverter}
                      style={{ border: 'none', borderRadius: '20px' }}
                      className="w-100 bg-dark mt-3 mb-3"
                    >
                      <img src={Vetor} width={15}></img>
                    </Button>
                    <img src={Green} width={60}></img>
                    <Typography fontWeight="500" fontSize={18}>
                      {tokenList.tpc ? tokenList.tpc.quantity : 0}{' '}
                      {tokenList.tpc ? tokenList.tpc.type : 'TPC'}
                    </Typography>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="7 mt-3">
                <Card className="w-100 h-100">
                  <Card.Body>
                    <h3>Dados do cartão</h3>
                    <Row className="mt-5">
                      <Col sm="6">
                        <div>
                          <h6>Nome</h6>
                          <h6 style={{ fontWeight: '600' }}>Carla Torres</h6>
                        </div>
                        <div className="mt-4">
                          <h6>Validade</h6>
                          <h6 style={{ fontWeight: '600' }}>08/21</h6>
                        </div>
                      </Col>
                      <Col sm="6 ">
                        <div className="">
                          <h6>Número do cartão</h6>
                          <h6 style={{ fontWeight: '600' }}>
                            **** ***** ***** ***
                          </h6>
                        </div>
                        <div className="mt-4">
                          <h6>CSV</h6>
                          <h6 style={{ fontWeight: '600' }}>***</h6>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="4 mt-5">
            <Card
              className="w-100  h-100 text-white p-4"
              style={{
                backgroundColor: '#272727',
                borderRadius: '20px'
              }}
            >
              <Typography fontWeight="500" color={'#B8B8B8'} fontSize={28}>
                Sua Carteira
              </Typography>
              <Typography
                fontWeight="500"
                color={'#FFFFFF'}
                sx={{
                  marginTop: '0rem',
                  cursor: 'pointer',
                  marginBottom: '0.5rem',
                  fontSize: { xs: '34px', xl: '24px', lg: '24px' },
                  overflowWrap: 'break-word',
                  wordBreak: 'break-all'
                }}
                // onMouseOver={handleMouseOver}
                // onMouseOut={handleMouseOut}
                // data-tip={tokenList?.walletAddress}
              >
                <Link
                  href={`https://mumbai.polygonscan.com/address/${tokenList?.walletAddress}#tokentxns`}
                  target="_blank"
                  underline="none"
                  sx={{
                    color: '#FFFFFF',
                    '&:hover': {
                      color: '#B8B8B8'
                    }
                  }}
                >
                  {tokenList?.walletAddress}↗
                </Link>
              </Typography>
              <Typography
                fontWeight="500"
                sx={{ marginTop: '1rem' }}
                color={'#B8B8B8'}
                fontSize={28}
              >
                Seus Créditos
              </Typography>
              <Typography
                fontWeight="500"
                color={'#FFFFFF'}
                sx={{ marginTop: '0rem' }}
                fontSize={42}
              >
                {creditAmount}
                <Typography
                  fontWeight="500"
                  color={'#B8B8B8'}
                  fontSize={24}
                  component={'span'}
                >
                  {' '}
                  BRL
                </Typography>
              </Typography>

              <Button
                className="w-100"
                onClick={onAdd}
                style={{
                  backgroundColor: '#9747FF',
                  border: 'none'
                }}
              >
                Adicionar
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="bg-white p-3 border rounded mb-5 mt-3">
          <div style={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 }
                }
              }}
              pageSizeOptions={[10, 20]}
            />
          </div>
        </div>
      </Container>
    </DashboardLayout>
  )
}

export default Wallet
