import {
  AutorenewOutlined,
  Cloud,
  ContentCopy,
  ContentCut,
  ContentPaste,
  HelpOutlineOutlined,
  HomeOutlined,
  LogoutSharp,
  PersonOutlineOutlined,
  SettingsOutlined,
  Wallet,
} from "@mui/icons-material";
import "../../app/globals.css";
import {
  Avatar,
  Box,
  Divider,
  Icon,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LogoPurple from "../../assets/logo-purple.svg"
import { NavLink, Navigate, useLocation } from "react-router-dom";
import ForestIcon from '@mui/icons-material/Forest';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
export const DashboardLayout = ({ children, name, email, role }) => {
  const [selected, setSelected] = useState(1);
  
  const [marginLeft, setMarginLeft] = useState('-800px');
  const [isOpened, setIsOpened] = useState(false);

  const handleOpenClick = () => {
    setMarginLeft('0');
    setIsOpened(true);
  };

  const handleCloseClick = () => {
    setMarginLeft('-800px');
    setIsOpened(false);
  };
  const user= localStorage.getItem("user");
  const userLogado= JSON.parse(user)
  
  console.log(userLogado.logoUser)
  const handleSelect = (select) => {
    setSelected(select);
  };

  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  const menuOptions = [
    {
      id: 0,
      name: "Transações",
      route: "/transactions",
      icon: (
        <AutorenewOutlined
          sx={{
            marginLeft: 2,
            color: `${
              path === "/transactions" ? "#AC7AFE" : "rgba(255, 255, 255, 0.4)"
            }`,
            width: { xs: "22px", xl: "34px" },
            height: { xs: "22px", xl: "34px" },
          }}
        />
      ),
    },
    {
      id: 1,
      name: "Início",
      route: "/search",
      icon: (
        <HomeOutlined
          sx={{
            marginLeft: 2,
            color: `${
              path === "/search" ? "#AC7AFE" : "rgba(255, 255, 255, 0.4)"
            }`,
            width: { xs: "22px", xl: "38px" },
            height: { xs: "22px", xl: "38px" },
          }}
        />
      ),
    },
    {
      id: 2,
      name: "Carteira",
      route: role === "ADMIN" ? "/wallet_admin": "/wallet",

      icon: (
        <Wallet
          sx={{
            marginLeft: 2,
            color: `${
              path === "/wallet" ? "#AC7AFE" : "rgba(255, 255, 255, 0.4)"
            }`,
            width: { xs: "22px", xl: "34px" },
            height: { xs: "22px", xl: "34px" },
          }}
        />
      ),
    },
    {
      id: 3,
      name: "Perfil Público",
      route: "/dashboard",
      icon: (
        <PersonOutlineOutlined
          sx={{
            marginLeft: 2,
            color: `${
              path === "/dashboard" ? "#AC7AFE" : "rgba(255, 255, 255, 0.4)"
            }`,
            width: { xs: "22px", xl: "34px" },
            height: { xs: "22px", xl: "34px" },
          }}
        />
      ),
    },
    {
      id: 4,
      name: "Início",
      route: "/provider",
      icon: (
        <ForestIcon
          sx={{
            marginLeft: 2,
            color: `${
              path === "/provider" ? "#AC7AFE" : "rgba(255, 255, 255, 0.4)"
            }`,
            width: { xs: "22px", xl: "34px" },
            height: { xs: "22px", xl: "34px" },
          }}
        />
      ),
    },
    {
      id: 5,
      name: "Cadastrar lote",
      route: "/lots",
      icon: (
        <HomeOutlined
          sx={{
            marginLeft: 2,
            color: `${
              path === "/lots" ? "#AC7AFE" : "rgba(255, 255, 255, 0.4)"
            }`,
            width: { xs: "22px", xl: "34px" },
            height: { xs: "22px", xl: "34px" },
          }}
        />
      ),
    },
    
    {
      id: 6,
      name: "Sair",
      route: "/signin",
      icon: (
        <LogoutSharp
          sx={{
            marginLeft: 2,
            color: `${"rgba(255, 255, 255, 0.4)"}`,
            width: { xs: "22px", xl: "34px" },
            height: { xs: "22px", xl: "34px" },
          }}
        />
      ),
    },
  ];

  let menuContent

  if(role === "ADMIN") {
    menuContent = menuOptions
    .filter((option) => option.id <= 2)
    .map((option) =>
      option.route === "/search" ? null : (
        <Link
          underline="none"
          key={option.id}
          href={option.route}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: { xs: 0, xl: 1.4 },
            marginTop: { xs: 1.2, xl: 2 },
            cursor: "pointer",
            borderLeft: `${
              path === option.route
                ? "3px solid #AC7AFE"
                : "3px solid transparent"
            }`,
          }}
        >
          <ListItemIcon>{option.icon}</ListItemIcon>
          <Typography
            sx={{
              color: `${
                path === option.route
                  ? "#AC7AFE"
                  : "rgba(255, 255, 255, 0.4)"
              }`,
              fontSize: { xs: 15, xl: 22 },
            }}
          >
            {option.name}
          </Typography>
        </Link>
      )
    )
  }
  else if(role === "COMPANY") {
    menuContent = menuOptions
      .filter((option) => option.id <= 3)
      .map((option) =>
        (option.route === "/transactions" || option.route === "/lots" ) ? null : (
          <Link
            underline="none"
            href={option.route}
            key={option.id}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: { xs: 0, xl: 1.4 },
              marginTop: { xs: 1.2, xl: 2 },
              borderLeft: `${
                path === option.route
                  ? "3px solid #AC7AFE"
                  : "3px solid transparent"
              }`,
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <Typography
              sx={{
                color: `${
                  path === option.route
                    ? "#AC7AFE"
                    : "rgba(255, 255, 255, 0.4)"
                }`,
                fontSize: { xs: 15, xl: 22 },
              }}
            >
              {option.name}
            </Typography>
          </Link>
        )
      )
  }
  else {
    // role === "SUPPLIER"
    menuContent = menuOptions
      .filter((option) => option.id <= 5)
      .map((option) =>
        (!(option.route === "/provider") && !(option.route === "/lots") ) ? null : (
          <Link
            underline="none"
            href={option.route}
            key={option.id}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: { xs: 0, xl: 1.4 },
              marginTop: { xs: 1.2, xl: 2 },
              borderLeft: `${
                path === option.route
                  ? "3px solid #AC7AFE"
                  : "3px solid transparent"
              }`,
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <Typography
              sx={{
                color: `${
                  path === option.route
                    ? "#AC7AFE"
                    : "rgba(255, 255, 255, 0.4)"
                }`,
                fontSize: { xs: 15, xl: 22 },
              }}
            >
              {option.name}
            </Typography>
          </Link>
        )
      )
  }

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Paper
      className=""
        sx={{
          width: { xs: "280px", xl: "390px" },

          height: "100%",
          top: 0,
          left: 0,
          position: "fixed",
          backgroundColor: "#1E1E1E",
          '@media (max-width: 570px)': { marginLeft: marginLeft},
          transition: '0.1s'
          
        }}
      >
         {isOpened && (
        <div>
          <button className="d-sm-none btn m-1 float-end text-white" onClick={handleCloseClick}>
            <CloseIcon/>
          </button>
        </div>
      )}
        <MenuList
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MenuItem sx={{ flex: 0.1, padding: '0', paddingLeft: '10px' }}>
            <Box
            className="p-0"
            >
              <img
                src={LogoPurple}
                style={{
                  width: "100%",
                }}
              />
            </Box>
          </MenuItem>
          <Divider
            sx={{
              backgroundColor: "#303031",
            }}
          />
          <Box sx={{ flex: { xs: 0.6, xl: 0.6 } }}>
            {menuContent}
          </Box>
          <Divider
            sx={{
              backgroundColor: "#303031",
              
            }}
          />
          <Box sx={{ flex: { xs: 0.3, xl: 0.4 } }}>
            {menuOptions
              .filter((option) => option.id > 5)
              .map((option) =>
                option.route === "/transactions" ? null : (
                  <Link
                    key={option.id}
                    underline="none"
                    href={option.route}
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      gap: { xs: 0, xl: 1.4 },

                      marginTop: { xs: 1.2, xl: 2.5 },
                    }}
                  >
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <Typography
                      sx={{
                        color: `${"rgba(255, 255, 255, 0.4)"}`,
                        fontSize: { xs: 15, xl: 22 },
                      }}
                    >
                      {option.name}
                    </Typography>
                  </Link>
                )
              )}
            <MenuItem
            component={NavLink}
            to="/dashboard"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: { xs: 4, xl: 10 },
                gap: { xs: 1, xl: 2 },
              }}
            >
              <Avatar
                src={userLogado?.logoUser ? userLogado?.logoUser : "avatar.png"}
                sx={{
                  width: { xl: 59 },
                  height: { xl: 59 },
                }}
              />
              <Box>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: { xs: 15, xl: 20 },
                  }}
                >
                  {userLogado.username}
                  
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.4)",
                    fontSize: { xs: 13, xl: 15 },
                  }}
                >
                  {userLogado.email}
                </Typography>
              </Box>
            </MenuItem>
          </Box>
        </MenuList>
      </Paper>

      <Box
        sx={{
          width: '100%',
          overflowX: 'auto',
          marginLeft: { xs: '280px', xl: '390px' },
          '@media (max-width: 565px)': { marginLeft: '0px'} 
        }}
      >
        {!isOpened && (
        <div className="bg-light border p-1 d-sm-none">
          <button className="d-sm-none m-1 btn  " onClick={handleOpenClick}>
            <MenuIcon/>
          </button>
        </div>
      )}
        {!isOpened && (
        <div>
          {children}
        </div>
      )}
      </Box>
    </Box>
  );
};
