import { Navbar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CustomButton } from "../../styles/index.jsx";

import Logo from "../../assets/logo-purple.svg";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

export const MenuNav = () => {
    return (
        <>

<Navbar expand="lg" style={{}} className="navbar-light">
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={Logo}
            style={{
              width: "100%",
            }}
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{border: 'none'}} className='text-white '>
          <MenuIcon/>
           </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto">
            <Nav.Link className='text-light pt-3' href="/">Início</Nav.Link>
            <Nav.Link className='text-light pt-3' href="/searchCompanies">Empresas</Nav.Link>
            <Nav.Link className='text-light pt-3' href="/signin">Entrar </Nav.Link>
            <Nav.Link className='text-light'>
            <Link to="/signup">
  <CustomButton
    sx={{
      width: { xs: "118px", xl: "150px" },
      height: { xs: "37px", xl: "44px" },
      fontSize: { xs: 11, xl: 16 },
    }}
  >
    Criar conta
  </CustomButton>
</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </>
    )
    }