export const getCnpjMask = (value) => {
  return value
    ?.replace(/\D/g, '') // remove qualquer caracter que não seja número
    ?.replace(
      /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/,
      (match, p1, p2, p3, p4, p5) => {
        let formatted = ''
        if (p2 === undefined) {
          formatted = p1
        } else if (p3 === undefined) {
          formatted = `${p1}.${p2}`
        } else if (p4 === undefined) {
          formatted = `${p1}.${p2}.${p3}`
        } else if (p5 === undefined) {
          formatted = `${p1}.${p2}.${p3}/${p4}`
        } else {
          formatted = `${p1}.${p2}.${p3}/${p4}-${p5}`
        }
        return formatted
      }
    )
    .substr(0, 18) // limita o tamanho do CNPJ em 18 caracteres
}

export const removeCnpjMask = (value) => {
  return value?.replace(/\D/g, '')
}
