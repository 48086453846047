import { Box, Link, styled, Typography } from "@mui/material";
import { CustomButton, Field } from "../styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoWhite from "../assets/logowhite.svg";

export default function RecoverPassword() {
  const [concluid, setConcluid] = useState(1);


  const handleNext = () => {
    setConcluid(2);
  };
  let navigate = useNavigate();

  const handlePrev = () => {
    setConcluid(1);
  };
  const handleSubmit = () => {
    navigate("/redefine_password");
  };
  const handleBack = () => {
    navigate("/signin");
  };

  return (
    <Box>
      {/* <Head>
        <title> Recuperar senha | Portal de Transparência de Carbono</title>
        <meta name="description" content="  Recuperar senha" />
      </Head> */}
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, #9747FF, #4E3972, #31343B)",
          height: "100vh",
          width: "100%",
          display: "flex",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <img src={LogoWhite} width={"400px"}></img>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {concluid === 1 && (
            <Box sx={{ padding: "10rem" }}>
              <Typography fontWeight={500} color={"#525252"} fontSize={32}>
                Recuperar senha
              </Typography>
              <Typography color={"#888888"} fontSize={15}>
                Digite o endereço de e-mail que você usou para se inscrever no
                Portal. Enviaremos um link para redefinir sua senha.
              </Typography>
              <Box sx={{ marginTop: "2rem" }}>
                <Typography
                  sx={{ marginBottom: "0.5rem" }}
                  color={"#31343B"}
                  fontWeight={500}
                  fontSize={15}
                >
                  Email
                </Typography>

                <Field placeholder="email@name.com" />
              </Box>
              <Box>
                <CustomButton onClick={handleNext} sx={{ marginTop: "2rem" }}>
                  Enviar
                </CustomButton>
                <CustomButton
                  onClick={handleBack}
                  sx={{ marginTop: "0.8rem" }}
                  secondarycolor="#BDFEA4"
                >
                  Voltar
                </CustomButton>
              </Box>
            </Box>
          )}
          {concluid === 2 && (
            <Box sx={{ padding: "10rem" }}>
              <Typography fontWeight={500} color={"#525252"} fontSize={32}>
                Verifique seu email
              </Typography>
              <Typography color={"#888888"} fontSize={18}>
                Enviamos um e-mail com informações de redefinição de senha para
                n****e@e***e.com.
              </Typography>
              <Typography
                sx={{ marginTop: "1rem" }}
                color={"#888888"}
                fontSize={18}
              >
                Não recebeu o e-mail? Verifique a pasta de spam ou
              </Typography>

              <Box>
                <CustomButton onClick={handleSubmit} sx={{ marginTop: "2rem" }}>
                  Reenviar email
                </CustomButton>
                <CustomButton
                  onClick={handlePrev}
                  sx={{ marginTop: "0.8rem" }}
                  secondarycolor="#BDFEA4"
                >
                  Voltar
                </CustomButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
