import { AppLayout } from '../components/appLayout'
import {
  Box,
  Button,
  Divider,
  Link,
  Modal,
  styled,
  Typography
} from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import { CustomButton, Field } from '../styles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Pagination } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { IconButton } from '@mui/material'
import CoinPurple from '../assets/coin-purple.svg'
import CoinGreen from '../assets/coin-green.svg'
import CoinRed from '../assets/coin-red.svg'
import s3 from '../helpers/aws.config'

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import {
  ArrowForward,
  CallMade,
  East,
  ParkOutlined,
  RemoveRedEyeOutlined
} from '@mui/icons-material'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useState } from 'react'
import { useEffect } from 'react'
import BasicModal from '../components/modal'
import { json } from 'react-router-dom'
import { getCnpjMask, removeCnpjMask } from '../helpers/getCnpjMask'
import Swal from 'sweetalert2'
import { Link as RouterLink } from 'react-router-dom'
import { Col, Container } from 'react-bootstrap'

const formValidation = yup.object({
  nome: yup.string().required('O nome é obrigatório'),
  descricao: yup.string().required('A descrição é obrigatória'),
  areaTotal: yup.number().required('A área total é obrigatório'),
  valor: yup.number().required('O valor é obrigatório')
})
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  textAlign: 'center',
  p: 4
}

function removeSpecialCharacters(str) {
  return str.replace(/[^\w\s]/gi, '').replace(/\s+/g, '')
}

const Lot = () => {
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')
  const [transactionList, setTransactionList] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))

  const fetchTransactionList = async () => {
    try {
      const response = await axios.post(
        `https://dev-back.itcarbon.org/api/tokens/transaction/`,
        {
          userId: user.id
        }
      )
      setTransactionList(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTransactionList()
  }, [])

  function converterParaData(data) {
    const dataObjeto = new Date(data)
    const options = { timeZone: 'America/Sao_Paulo' }
    const dataBrasileira = dataObjeto.toLocaleDateString('pt-BR', options)
    return dataBrasileira
  }

  const {
    setValue,
    reset,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidation)
  })
  const [openModal, setOpenModal] = useState(false)
  const [tokenList, setTokenList] = useState([])

  const userToken = localStorage.getItem('userToken')
  const token = JSON.parse(userToken)

  const [landPlotLogo, setLandPlotLogo] = useState()

  const handleUploadFile = (file) => {
    const formData = getValues()

    const nameFormated = `${removeSpecialCharacters(
      String(user.id)
    )}-${removeSpecialCharacters(formData.nome)}-land-plot`
    const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
    console.log(nameFormated)

    const params = {
      Bucket: 'statics-pct',
      Key: `${nameFormated}.${fileExtension}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read'
    }

    s3.upload(params, async (error, data) => {
      if (error) {
        console.log(error)
      } else {
        console.log('File uploaded successfully!', data.Location)
        setLandPlotLogo(await data?.Location)
      }
    })
  }

  const onFinish = async (data) => {
    console.log(data.valor, data.areaTotal)
    setLoadingTitle('Cadastrando lote, aguarde...')
    setRequisitionLoading(true)
    try {
      await axios.post(
        'https://dev-back.itcarbon.org/api/suppliers/land-plot',
        {
          logoSupplier: landPlotLogo ? landPlotLogo : '',
          landPlot: {
            name: data.nome,
            description: data.descricao,
            value: data.valor,
            area: data.areaTotal,
            supplierId: user.supplierId
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Sucesso!',
        'Cadastro de fornecedor realizado, um Administrador irá avaliar a solicitação assim que possível',
        'success'
      ).then(() => {
        window.location.reload()
      })

      reset()
    } catch (error) {
      console.error(error)
      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro.', 'Ocorreu um erro ao cadastrar o lote.', 'error').then(
        () => {
          window.location.reload()
        }
      )
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: loadingTitle,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  console.log(user)

  const [isNomeInserted, setIsNomeInserted] = useState(false)

  const handleNomeChange = (event) => {
    const nome = event.target.value
    if (nome.length > 0) {
      setIsNomeInserted(true)
    } else {
      setIsNomeInserted(false)
    }
  }

  return (
    <DashboardLayout email={user.email} name={user.username} role={user.role}>
      <Container>
        <Col sm="6 mt-5 pt-5">
            <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFFFFF',
            width: '100%'
          }}
        >
          <Box>
            <Typography
              sx={{ marginBottom: '2rem' }}
              fontWeight="600"
              color="#2F2D2B"
              fontSize={30}
            >
              Cadastrar lote
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography
              color="#1F441E"
              variant="body2"
              fontWeight="500"
              marginBottom={0.2}
            >
              Nome do lote
            </Typography>
            <Controller
              name="nome"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <Field
                  {...field}
                  placeholder="Nome do lote"
                  helperText={fieldState.error?.message}
                  error={!!errors.nome}
                  // value={value}
                  id="nome"
                  name="nome"
                  autoComplete="Nome"
                  {...register('nome')}
                  onChange={(e) => {
                    field.onChange(e)
                    handleNomeChange(e)
                  }}
                />
              )}
            />
          </Box>

          <Box marginBottom={2}>
            <Typography
              color="#1F441E"
              variant="body2"
              fontWeight="500"
              marginBottom={0.2}
            >
              Descrição
            </Typography>
            <Controller
              name="descricao"
              control={control}
              defaultValue=""
              render={({ field: { value }, fieldState }) => (
                <Field
                  placeholder="Descreva o que contém neste lote"
                  helperText={fieldState.error?.message}
                  error={!!errors.descricao}
                  value={value}
                  id="descricao"
                  name="descricao"
                  autoComplete="Descrição"
                  {...register('descricao')}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5 }} marginBottom={2}>
            <Box flex={0.5}>
              <Typography
                color="#1F441E"
                variant="body2"
                fontWeight="500"
                marginBottom={0.2}
              >
                Área total
              </Typography>
              <Controller
                name="areaTotal"
                control={control}
                defaultValue=""
                render={({ field: { value }, fieldState }) => (
                  <Field
                    placeholder="m²"
                    helperText={fieldState.error?.message}
                    error={!!errors.areaTotal}
                    value={value}
                    id="areaTotal"
                    name="areaTotal"
                    autoComplete="Área Total"
                    {...register('areaTotal')}
                  />
                )}
              />
            </Box>
            <Box flex={0.5}>
              <Typography
                color="#1F441E"
                variant="body2"
                fontWeight="500"
                marginBottom={0.2}
              >
                Valor
              </Typography>
              <Controller
                name="valor"
                control={control}
                defaultValue=""
                render={({ field: { value }, fieldState }) => (
                  <Field
                    placeholder="TPC"
                    helperText={fieldState.error?.message}
                    error={!!errors.valor}
                    value={value}
                    id="valor"
                    name="valor"
                    autoComplete="Valor"
                    {...register('valor')}
                  />
                )}
              />
            </Box>
          </Box>

          <Box
            sx={{ margin: '1rem', marginLeft: '0px' }}
            disabled={!isNomeInserted}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={(event) => handleUploadFile(event.target.files[0])}
                disabled={!isNomeInserted}
              />
              <button
              className='btn '
                style={{
                  backgroundColor: '#9747FF',
                  color: 'white',
                  ':hover': {
                    backgroundColor: ' #b783ff '
                  }
                }}
                disabled={!isNomeInserted}
                component="span"
              >
                Adicionar imagem
              </button>
            </label>
          </Box>

          <Divider />
          <CustomButton
            sx={{ marginTop: '1rem' }}
            onClick={handleSubmit(onFinish)}
          >
            Cadastrar
          </CustomButton>
        </Box>
        </Col>
      </Container>
      
    </DashboardLayout>
  )
}

export default Lot
