import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '../pages/home'
import CompanyPublic from '../pages/companyPublic'
import SignUp from '../pages/signup'
import SignIn from '../pages/signin'
import Search from '../pages/search'
import Wallet from '../pages/wallet'
import Dashboard from '../pages/dashboard'
import Ground from '../pages/grounds'
import Lot from '../pages/lote'
import Provider from '../pages/dashboard-fornecedor'
import Transaction from '../pages/transactions'
import Recover from '../pages/recover_password'
import Redefine from '../pages/redefine_password'
import WalletAdmin from '../pages/wallet_admin'
import SearchCompanies from '../pages/searchCompanies' 

export const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/companyPublic/:slug" element={<CompanyPublic />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/search" element={<Search />} />
        <Route path="/searchCompanies" element={<SearchCompanies />} />
        <Route path="/transactions" element={<Transaction />} />
        <Route path="/recover_password" element={<Recover />} />
        <Route path="/redefine_password" element={<Redefine />} />
        <Route path="/wallet_admin" element={<WalletAdmin />} />
        <Route path="/grounds" element={<Ground />} />
        <Route path="/lots" element={<Lot />} />
        <Route path="/provider" element={<Provider />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/dashboard" element={<Dashboard />} />

      </Routes>
    </Router>
  )
}
