import { useEffect, useState } from 'react'
import axios from 'axios'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { Box, Typography, styled } from '@mui/material'
import Green from '../assets/green.svg'
import Arvore from '../assets/arvore.png'
import { Divider, Link } from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import { CustomButton } from '../styles'
import Swal from 'sweetalert2'
import { Card, Col, Container, Row } from 'react-bootstrap'
import TransactionTable from '../components/table'

const Search = () => {
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')
  const [fornecedorList, setFornecedorList] = useState([])
  const [creditAmount, setCreditAmount] = useState(0)

  const user = localStorage.getItem('user')
  const userLogado = JSON.parse(user)

  const userToken = localStorage.getItem('userToken')
  const token = JSON.parse(userToken)

  const fetchFornecedorList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/suppliers/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setFornecedorList(response.data.landPlotList)
      console.log(response.data.landPlotList)
    } catch (error) {
      console.error(error)
    }
  }
  const [tokenList, setTokenList] = useState([])

  useEffect(() => {
    fetchFornecedorList()
  }, [])

  const fetchTokenList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/tokens/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setTokenList(response.data.response)
      setCreditAmount(response.data.response.creditBalance.ownedAmount)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTokenList()
  }, [])

  const onBuy = async (landPlot) => {
    setLoadingTitle('Realizando a compra do lote...')
    setRequisitionLoading(true)
    try {
      const response = await axios.post(
        'https://dev-back.itcarbon.org/api/suppliers/sell',
        {
          supplierId: landPlot.supplierId,
          landPlotId: landPlot.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
      setCreditAmount(
        (prevCreditAmount) => prevCreditAmount - landPlot.value * 100
      )

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Sucesso!', 'Compra realizada', 'success').then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Ocorreu algum erro!', 'error')
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: loadingTitle,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  const onAdd = async () => {
    try {
      const response = await axios.put(
        'https://dev-back.itcarbon.org/api/wallets/',
        {
          amount: 10000,
          paidValue: 10000 * 10
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setCreditAmount((prevCreditAmount) => prevCreditAmount + 10000)
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  }

  const [transactionList, setTransactionList] = useState([])

  const fetchTransactionList = async () => {
    try {
      const response = await axios.post(
        `https://dev-back.itcarbon.org/api/tokens/transaction/`,
        { userId: userLogado.id }
      )
      setTransactionList(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTransactionList()
  }, [])

  function converterParaData(data) {
    const dataObjeto = new Date(data)
    const options = { timeZone: 'America/Sao_Paulo' }
    const dataBrasileira = dataObjeto.toLocaleDateString('pt-BR', options)
    return dataBrasileira
  }

  console.log('teste2', tokenList.tdc ? tokenList.tdc.quantity : 0)

  const data = [
    {
      name: 'TCC',
      total: tokenList.tcc ? tokenList.tcc.quantity : 0,
      fill: '#9747FF'
    },
    {
      name: 'TDC',
      total: tokenList.tdc ? tokenList.tdc.quantity : 0,
      fill: '#E25A71'
    }
  ]

  // const [showFullAddress, setShowFullAddress] = useState(false)

  // const handleMouseOver = () => {
  //   setShowFullAddress(true)
  // }

  // const handleMouseOut = () => {
  //   setShowFullAddress(false)
  // }

  // const tooltipStyle = {
  //   backgroundColor: '#000000',
  //   color: '#FFFFFF',
  //   padding: '10px',
  //   fontWeight: 500,
  //   fontSize: '14px',
  //   borderRadius: '5px'
  // }

  return (
    <>
      <DashboardLayout
        email={userLogado.email}
        name={userLogado.username}
        role={userLogado.role}
      >
        <Container>
          <div className="mt-5">
            <h2 style={{ fontWeight: '600' }} color="#2F2D2B" fontSize={28}>
              Olá, {userLogado.username}!
            </h2>
            <h6 style={{ color: '#84848' }}>
              Gerencie seus fundos de emissão e captação de carbono.
            </h6>
          </div>
        </Container>
        <Container className="mt-5 mb-3">
          <Row>
            <Col lg="8">
              <Row>
                <Col sm="4 mb-2">
                  <Card className="mt-3 h-100 w-100">
                    <Card.Body>
                      <h6 style={{ color: '#848484' }}>Crédito de carbono</h6>
                      <h6 style={{ fontWeight: '600', color: '#2F2D2B' }}>
                        {tokenList.tcc ? tokenList.tcc.quantity : 0}{' '}
                        {tokenList.tcc ? tokenList.tcc.type : 'TCC'}
                      </h6>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="4 mb-2">
                  <Card className="mt-3 h-100 w-100">
                    <Card.Body>
                      <h6 style={{ color: '#848484' }}>Débito de carbono</h6>
                      <h6 style={{ fontWeight: '600', color: '#2F2D2B' }}>
                        {tokenList.tdc ? tokenList.tdc.quantity : 0}{' '}
                        {tokenList.tdc ? tokenList.tdc.type : 'TDC'}
                      </h6>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="4 mb-2">
                  <Card className="mt-3 h-100 w-100">
                    <Card.Body>
                      <h6 style={{ color: '#848484' }}>Projeção de carbono</h6>
                      <h6 style={{ fontWeight: '600', color: '#2F2D2B' }}>
                        {tokenList?.tpc ? tokenList?.tpc?.quantity : 0}{' '}
                        {tokenList?.tpc ? tokenList?.tpc?.type : 'TPC'}
                      </h6>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div>
                <div
                  className="bg-white border mt-3 mb-3 rounded"
                  style={{
                    width: '100%',
                    minHeight: '35.9rem',
                    overflow: 'auto',
                    padding: 16
                  }}
                >
                  {fornecedorList.map((list, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <img
                            src={list.logoSupplier ? list.logoSupplier : Arvore}
                            alt="Imagem do Lote de Terra"
                            style={{
                              maxHeight: '10rem',
                              maxWidth: '10rem',
                              borderRadius: '50%',
                              objectFit: 'cover'
                            }}
                            width={200}
                          ></img>
                          <div style={{ margin: '1rem' }}>
                            <h6 fontWeight="600" color="#2F2D2B" fontSize={20}>
                              {list.name}
                            </h6>
                            <h6
                              fontWeight="500"
                              color="#2F2D2B"
                              fontSize={15}
                              style={{
                                paddingTop: '0.3rem',
                                paddingBottom: '1rem'
                              }}
                            >
                              {list.description}
                            </h6>
                            <h6 fontWeight="400" color="#31343B" fontSize={18}>
                              {list.area}m²
                            </h6>
                            <div
                              style={{ display: 'flex', marginTop: '0.5rem' }}
                            >
                              <img src={Green} width={25}></img>
                              {list.value} TPC | {list.value * 100} BRL
                            </div>
                          </div>
                        </div>
                        <div>
                          <CustomButton
                            onClick={() => onBuy(list)}
                            style={{
                              width: 150,
                              margin: 'auto',
                              height: 35,
                              marginTop: '1.5rem'
                            }}
                          >
                            Adquirir
                          </CustomButton>
                        </div>
                      </div>

                      <Divider></Divider>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col lg="4">
              <Box
                sx={{
                  marginTop: '0rem',
                  marginBottom: '1rem',
                  backgroundColor: '#272727',
                  padding: '0.3rem',
                  borderRadius: '20px',
                  // backgroundImage: `url(${backgroundImage})`,
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '21rem'
                }}
              >
                <Box
                  className="p-4 mt-3 w-100 text-white"
                  style={{
                    backgroundColor: '#272727',
                    borderRadius: '20px'
                  }}
                >
                  <Typography
                    fontWeight="500"
                    color={'#B8B8B8'}
                    fontSize={28}
                    sx={{ paddingTop: '0.5rem' }}
                  >
                    Sua Carteira
                  </Typography>
                  <Typography
                    fontWeight="500"
                    color={'#FFFFFF'}
                    sx={{
                      marginTop: '0.5rem',
                      cursor: 'pointer',
                      marginBottom: '0.3rem',
                      fontSize: { xs: '20px', xl: '20px', lg: '20px' },
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all'
                    }}
                  >
                    <Link
                      href={`https://mumbai.polygonscan.com/address/${tokenList?.walletAddress}#tokentxns`}
                      target="_blank"
                      underline="none"
                      sx={{
                        color: '#FFFFFF',
                        textDecoration: 'none', // Remove underline
                        '&:hover': {
                          color: '#B8B8B8'
                        }
                      }}
                    >
                      {tokenList?.walletAddress}↗
                    </Link>
                  </Typography>
                  <Typography fontWeight="500" color={'#B8B8B8'} fontSize={28}>
                    Seus Créditos
                  </Typography>
                  <Typography
                    fontWeight="500"
                    color={'#FFFFFF'}
                    sx={{ marginTop: '0.5rem' }}
                    fontSize={42}
                  >
                    {creditAmount}
                    <Typography
                      fontWeight="500"
                      color={'#B8B8B8'}
                      fontSize={24}
                      component={'span'}
                    >
                      {' '}
                      BRL
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <div className="mt-3">
                <div
                  className="p-4 w-100 border"
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '10px'
                  }}
                >
                  <ResponsiveContainer width={'100%'} height={300}>
                    <BarChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="total" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="bg-white p-3 border rounded">
            <TransactionTable />
          </div>
        </Container>
      </DashboardLayout>
    </>
  )
}

export default Search
