import { Box, Divider, Link, styled, Typography } from "@mui/material";
import { colors, fonts } from "../../styles/styles.jsx";

import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LogoPurple from "../../assets/logo-purple.svg"
import { Col, Container, Row } from "react-bootstrap";
export const Footer = () => {
  return (
    <Container className="pt-5 m-0 mt-5" fluid style={{ background: "rgba(0, 0, 0, 0.14)", }}>
      <Row
       className="pb-2"
      >
        <Col sm="3 pb-3"
        >
          <img
          className="w-75"
            src={LogoPurple}
          />
        </Col>
        <Col>
            <h6>
  
          <Link
            underline="none"
            fontWeight="700"
            color={"#FFFFFF"}
            sx={{ fontSize: { xs: 16, xl: 18 } }}
          >
            Páginas
          </Link>
          </h6>
          <h6>

          <Link
            href="/"
            underline="none"
            color={"#FFFFFF"}
            sx={{ fontSize: { xs: 14, xl: 16 }, cursor: "pointer" }}
          >
            Início
          </Link>
          </h6>
          <h6>
          <Link
            href="/searchCompanies"
            underline="none"
            color={"#FFFFFF"}
            sx={{ fontSize: { xs: 14, xl: 16 }, cursor: "pointer" }}
          >
            Empresas
          </Link>
          </h6>
       
        </Col>
        <Col 
        
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 1, xl: 2 },
          }}
        >
            <h6>
            <Link
            underline="none"
            fontWeight="700"
            color={"#FFFFFF"}
            sx={{ fontSize: { xs: 16, xl: 18 } }}
          >
            Menu
          </Link>
            </h6>
         <h6>
         <Link
            href="/signin"
            underline="none"
            color={"#FFFFFF"}
            sx={{ fontSize: { xs: 14, xl: 16 }, cursor: "pointer" }}
          >
            Entrar
          </Link>
         </h6>
          <h6>
          <Link
            href="/signup"
            underline="none"
            color={"#FFFFFF"}
            sx={{ fontSize: { xs: 14, xl: 16 }, cursor: "pointer" }}
          >
            Criar conta
          </Link>
          </h6>
         
        </Col>

        <Col sm="3  " style={{color: '#9747FF'}}>
          <div className="mx-auto d-block text-center mt-3">

          <FacebookTwoToneIcon
            style={{
              width: { xs: "22px", xl: "28px" },
              height: { xs: "22px", xl: "28px" },
              marginRight: { xs: "10px", xl: "17px" },
            }}
          />

          <InstagramIcon
            style={{
              width: { xs: "22px", xl: "28px" },
              height: { xs: "22px", xl: "28px" },
              marginRight: { xs: "10px", xl: "17px" },
            }}
          />
          <TwitterIcon
            style={{
              width: { xs: "22px", xl: "28px" },
              height: { xs: "22px", xl: "28px" },
              marginRight: { xs: "10px", xl: "17px" },
            }}
          />
          <LinkedInIcon
            style={{
              width: { xs: "22px", xl: "28px" },
              height: { xs: "22px", xl: "28px" },
              marginRight: { xs: "10px", xl: "17px" },
            }}
          />
          </div>

        </Col >
      </Row>
      <Container fluid
        style={{
          marginTop: 7,
          marginBottom: 3,
          borderBottom: "1px solid #9747FF",
        }}
      />
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
        textAlign: "center",
        }}
      >
        <Typography
          paddingTop={1}
          color={"#FFFFFF"}
          sx={{ fontSize: { xs: 11, xl: 16 } }}
        >
          © Copyright 2023 Todos os direitos reservados.
        </Typography>
      </Container>
    </Container>
  );
};

export default Footer;
