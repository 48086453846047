import { AppLayout } from '../components/appLayout'
import { useNavigate, useNavigation } from 'react-router-dom'
import { CustomButton, Field } from '../styles'
import {
  Box,
  createTheme,
  Divider,
  Link,
  styled,
  ThemeProvider,
  Typography
} from '@mui/material'
import axios from 'axios'
// import Head from "next/head";
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const formValidation = yup.object({
  cnpj: yup.string().required('O CNPJ é obrigatório'),
  email: yup.string().required('O email é obrigatório'),
  password: yup.string().required('A senha é obrigatória'),
  responsible: yup.string().required('O responsável é obrigatório'),
  description: yup.string(),
  corporateName: yup.string().required('A razão Social é obrigatória'),
  fantasyName: yup.string().required('O nome fantasia é obrigatório'),
  address: yup.string().required('O endereço é obrigatório'),
  number: yup
    .number()
    .typeError('O número é obrigatório')
    .required('O número é obrigatório'),
  complement: yup.string(),
  neighborhood: yup.string().required('O bairro é obrigatório'),
  state: yup.string().required('O estado é obrigatório'),
  city: yup.string().required('A cidade é obrigatória')
})

const SignUp = () => {
  const {
    setValue,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidation)
  })

  const getCnpjMask = (value) => {
    return value
      ?.replace(/\D/g, '') // remove qualquer caracter que não seja número
      ?.replace(
        /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/,
        (match, p1, p2, p3, p4, p5) => {
          let formatted = ''
          if (p2 === undefined) {
            formatted = p1
          } else if (p3 === undefined) {
            formatted = `${p1}.${p2}`
          } else if (p4 === undefined) {
            formatted = `${p1}.${p2}.${p3}`
          } else if (p5 === undefined) {
            formatted = `${p1}.${p2}.${p3}/${p4}`
          } else {
            formatted = `${p1}.${p2}.${p3}/${p4}-${p5}`
          }
          return formatted
        }
      )
      .substr(0, 18) // limita o tamanho do CNPJ em 18 caracteres
  }

  function convertToNextJSFormat(data) {
    // Remover espaços em branco
    const step1 = data?.replace(/\s/g, '')

    // Converter para letras minúsculas
    const step2 = step1?.toLowerCase()

    // Substituir caracteres especiais e acentos por underscores
    const step3 = step2?.replace(/[^a-z0-9]/g, '_')

    return step3
  }

  let navigate = useNavigate()
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const onFinish = async (data) => {
    setRequisitionLoading(true)
    try {
      const response = await axios.post(
        'https://dev-back.itcarbon.org/api/companies/',
        {
          cnpj: data.cnpj,
          address: data.address,
          city: data.city,
          state: data.state,
          streetNumber: data.number,
          neighborhood: data.neighborhood,
          addressLine2: data.complement,
          description: data.description,
          legalName: data.corporateName,
          brandName: data.fantasyName,
          logoCompany: '',
          manager: data.responsible,
          slug: convertToNextJSFormat(data.corporateName),
          user: {
            email: data.email,
            password: data.password,
            username: data.responsible
          }
        },
        {
          headers: {
            apiauth:
              'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlTZWNyZXQiOiJjNDY0ZGVlODBkMGNlNTYwMmQ3OTI1ZmY5ZGEzYmUzZWY3Y2E5MzFiNTQ2YTk2MGY1ZGM2NDQ0MjMzZWUzYmNjIn0.S4qdBBlWDyaPRjsTUZUb_zbqHpdz4wTYKyAe35ILRSs'
          }
        }
      )
      console.log(response)

      Swal.close()
      Swal.fire('Sucesso!', 'conta criada com sucesso!', 'success')
      navigate('/signin')
    } catch (error) {
      Swal.close()
      Swal.fire('Erro.', 'Ocorreu um erro ao cadastrar nova empresa.', 'error')
      setRequisitionLoading(false)
      console.error(error)
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: 'Cadastrando empresa, aguarde...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  } else {
    Swal.close()
  }

  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <Helmet>
          <title>Cadastrar | Portal de Transparência de Carbono</title>
          <meta name="description" content="Cadastrar" />
        </Helmet>

        <Container fluid className='p-0 p-sm-3'>
          

        <Box
className="p-4 p-sm-5"
         sx={{
            width: '100%',
            height: '100%',
            borderRadius: 2,
            background:
              'linear-gradient(111.9deg, rgba(151, 71, 255, 0.6) 0.07%, rgba(22, 24, 26, 0.24) 95.84%)',
            boxShadow: '-3px 11px 41px rgba(0, 0, 0, 0.05)'
          }}
        >
          <Box className="">
            <Typography
              fontWeight="600"
              color="#FFFFFF"
              sx={{
                fontSize: { xs: 26, xl: 48 },
                marginBottom: { xs: 0.5, xl: 1 }
              }}
            >
              Cadastro
            </Typography>
            <Typography
              component="h4"
              color="#FFFFFF"
              sx={{ fontSize: { xs: 16, xl: 24 } }}
            >
              Por favor, informa seus dados abaixo
            </Typography>
          </Box>
          <Row>
          
      <Col sm="6">
        <Box
                className="w-100 "
        
                
              >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  paddingY: 3
                }}
              >
                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Email
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Digite seu email"
                        helperText={fieldState.error?.message}
                        error={!!errors.email}
                        value={value}
                        id="email"
                        name="name"
                        autoComplete="Email"
                        {...register('email')}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Senha
                  </Typography>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Digite sua senha"
                        helperText={fieldState.error?.message}
                        error={!!errors.password}
                        value={value}
                        id="password"
                        name="password"
                        autoComplete="Senha"
                        {...register('password')}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Responsável
                  </Typography>

                  <Controller
                    name="responsible"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Digite o nome do responsável"
                        helperText={fieldState.error?.message}
                        error={!!errors.responsible}
                        value={value}
                        id="responsible"
                        name="responsible"
                        autoComplete="Responsável"
                        {...register('responsible')}
                      />
                    )}
                  />
                </Box>

                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Descrição da empresa
                  </Typography>

                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        multiline
                        sx={{ minRows: { xs: 4.2, xl: 17.2 } }}
                        placeholder="A Empresa Legal é líder no desenvolvimento de eletrônicos inovadores, atendendo a diversos setores como eletrônicos de consumo."
                        helperText={fieldState.error?.message}
                        error={!!errors.description}
                        value={value}
                        id="description"
                        name="description"
                        autoComplete="  Descrição da empresa"
                        {...register('description')}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    CNPJ
                  </Typography>

                  <Controller
                    name="cnpj"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="00.000.000/0000-00"
                        helperText={fieldState.error?.message}
                        error={!!errors.cnpj}
                        value={getCnpjMask(value)}
                        id="cnpj"
                        name="cnpj"
                        autoComplete="CNPJ"
                        inputProps={{ maxLength: 18 }}
                        {...register('cnpj')}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
      </Col>
      <Col sm="6">
      <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  paddingY: 3
                }}
              >
             
                <Box
                >
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Razão Social
                  </Typography>

                  <Controller
                    name="corporateName"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Empresa Legal LTDA"
                        helperText={fieldState.error?.message}
                        error={!!errors.corporateName}
                        value={value}
                        id="corporateName"
                        name="corporateName"
                        autoComplete="Razão Social"
                        {...register('corporateName')}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Nome fantasia
                  </Typography>
                  <Controller
                    name="fantasyName"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="Empresa Legal"
                        helperText={fieldState.error?.message}
                        error={!!errors.fantasyName}
                        value={value}
                        id="fantasyName"
                        name="fantasyName"
                        autoComplete="Nome fantasia"
                        {...register('fantasyName')}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography
                    color="#FFFFFF"
                    marginBottom={0.7}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      fontWeight: { xs: '500' },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Endereço
                  </Typography>
                  <Controller
                    name="address"
                    control={control}
                    defaultValue=""
                    render={({ field: { value }, fieldState }) => (
                      <Field
                        placeholder="R. Jorge Amado "
                        helperText={fieldState.error?.message}
                        error={!!errors.address}
                        value={value}
                        id="address"
                        name="address"
                        autoComplete="Endereço"
                        {...register('address')}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <Box flex={0.2}>
                    <Typography
                      color="#FFFFFF"
                      marginBottom={0.7}
                      sx={{
                        fontSize: { xs: 14, xl: 21 },
                        fontWeight: { xs: '500' },
                        letterSpacing: { xl: 0.7 }
                      }}
                    >
                      Número
                    </Typography>

                    <Controller
                      name="number"
                      control={control}
                      defaultValue=""
                      render={({ field: { value }, fieldState }) => (
                        <Field
                          placeholder="158"
                          helperText={fieldState.error?.message}
                          error={!!errors.number}
                          value={value}
                          id="number"
                          name="number"
                          autoComplete="Número"
                          {...register('number')}
                        />
                      )}
                    />
                  </Box>

                  <Box flex={0.3}>
                    <Typography
                      color="#FFFFFF"
                      marginBottom={0.7}
                      sx={{
                        fontSize: { xs: 14, xl: 21 },
                        fontWeight: { xs: '500' },
                        letterSpacing: { xl: 0.7 }
                      }}
                    >
                      Complemento
                    </Typography>

                    <Controller
                      name="complement"
                      control={control}
                      defaultValue=""
                      render={({ field: { value }, fieldState }) => (
                        <Field
                          placeholder="Apto 200"
                          helperText={fieldState.error?.message}
                          error={!!errors.complement}
                          value={value}
                          id="complement"
                          name="complement"
                          autoComplete="Complemento"
                          {...register('complement')}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={0.5}>
                    <Typography
                      color="#FFFFFF"
                      marginBottom={0.7}
                      sx={{
                        fontSize: { xs: 14, xl: 21 },
                        fontWeight: { xs: '500' },
                        letterSpacing: { xl: 0.7 }
                      }}
                    >
                      Bairro
                    </Typography>

                    <Controller
                      name="neighborhood"
                      control={control}
                      defaultValue=""
                      render={({ field: { value }, fieldState }) => (
                        <Field
                          placeholder="Centro"
                          helperText={fieldState.error?.message}
                          error={!!errors.neighborhood}
                          value={value}
                          id="neighborhood"
                          name="neighborhood"
                          autoComplete="Bairro"
                          {...register('neighborhood')}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <Box flex={0.5}>
                    <Typography
                      color="#FFFFFF"
                      marginBottom={0.7}
                      sx={{
                        fontSize: { xs: 14, xl: 21 },
                        fontWeight: { xs: '500' },
                        letterSpacing: { xl: 0.7 }
                      }}
                    >
                      Estado
                    </Typography>

                    <Controller
                      name="state"
                      control={control}
                      defaultValue=""
                      render={({ field: { value }, fieldState }) => (
                        <Field
                          placeholder="SP"
                          helperText={fieldState.error?.message}
                          error={!!errors.state}
                          value={value}
                          id="state"
                          name="state"
                          autoComplete="Estado"
                          {...register('state')}
                        />
                      )}
                    />
                  </Box>
                  <Box flex={0.5}>
                    <Typography
                      color="#FFFFFF"
                      marginBottom={0.7}
                      sx={{
                        fontSize: { xs: 14, xl: 21 },
                        fontWeight: { xs: '500' },
                        letterSpacing: { xl: 0.7 }
                      }}
                    >
                      Cidade
                    </Typography>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field: { value }, fieldState }) => (
                        <Field
                          placeholder="São Paulo"
                          helperText={fieldState.error?.message}
                          error={!!errors.city}
                          value={value}
                          id="city"
                          name="city"
                          autoComplete="Cidade"
                          {...register('city')}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <CustomButton
                className=''
                  disabled={requisitionLoading}
                  onClick={handleSubmit(onFinish)}
                  sx={{
                    height: { xl: '64px' },
                    fontSize: { xl: '24px' }
                  }}
                >
                  Cadastrar
                </CustomButton>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 0.4
                  }}
                >
                  <Typography
                    color={'#FFFFFF'}
                    fontWeight={'500'}
                    sx={{
                      fontSize: { xs: 14, xl: 21 },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Já possui uma conta?
                  </Typography>
                  <Link
                    href="/signin"
                    disabled={requisitionLoading}
                    color={'#AC7AFE'}
                    fontWeight={'500'}
                    sx={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      fontSize: { xs: 14, xl: 21 },
                      letterSpacing: { xl: 0.7 }
                    }}
                  >
                    Faça login
                  </Link>
                </Box>
              </Box>
      </Col>
          
          <Box
           className="w-100"
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            
            
            </Box>
            
        </Row>
          
        </Box>

</Container>

      </AppLayout>
    </ThemeProvider>
  )
}

export default SignUp
