import AWS from 'aws-sdk'

AWS.config.update({
  accessKeyId: 'AKIATN53AIXZGN3BHYMY',
  secretAccessKey: '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',
  region: 'us-east-1'
})

const s3 = new AWS.S3()

export default s3
