import Empresa3 from "../../assets/empresa3.svg"
import { Card, CardContent, Typography, CardMedia, Box } from "@mui/material";
import { CallMade } from "@mui/icons-material";
import { useState } from "react";
import { useLocation , useNavigate } from "react-router-dom";

const CustomCard = ({ title, slug }) => {

  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  let navigate = useNavigate();

  const handleCompany = () => {
    navigate(`/companyPublic/${slug}`);
  };

  return (
    <Card
      onClick={handleCompany}
      sx={{
        marginBottom: 6,
        padding: 3,
        width: "325px",
        height: "107px",
        borderTopLeftRadius: "50px",
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
        borderBottomLeftRadius: `${path === "/searchCompanies" && "50px"}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        background:
          "linear-gradient(176.14deg, rgba(151, 71, 255, 0.3) -47.18%, rgba(151, 71, 255, 0) 134.24%)",
        boxSizing: " 0px 4px 50px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
      }}
    >
      <CardMedia>
        <img src={Empresa3} width="60px" />
      </CardMedia>
      <CardContent>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.4,
            marginTop: -0.6,
          }}
        >
         
          
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
