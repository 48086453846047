import { AppLayout } from '../components/appLayout'
import {
  Box,
  Button,
  Divider,
  Link,
  Modal,
  styled,
  Typography
} from '@mui/material'
import { DashboardLayout } from '../components/dashboardLayout'
import { CustomButton, Field } from '../styles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Pagination } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { IconButton } from '@mui/material'
import Arvore from '../assets/arvore.png'
import CoinPurple from '../assets/coin-purple.svg'
import CoinGreen from '../assets/coin-green.svg'
import CoinRed from '../assets/coin-red.svg'
import Green from '../assets/green.svg'
import s3 from '../helpers/aws.config'

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import {
  ArrowForward,
  CallMade,
  East,
  ParkOutlined,
  RemoveRedEyeOutlined
} from '@mui/icons-material'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useState } from 'react'
import { useEffect } from 'react'
import BasicModal from '../components/modal'
import { json } from 'react-router-dom'
import { getCnpjMask, removeCnpjMask } from '../helpers/getCnpjMask'
import Swal from 'sweetalert2'
import { Link as RouterLink } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

const formValidation = yup.object({
  nome: yup.string().required('O nome é obrigatório'),
  cnpj: yup.string().required('O CNPJ é obrigatório'),
  email: yup.string().email().required('O email do fornecedor é obrigatório'),
  password: yup.string().required('A senha do fornecedor é obrigatória')
  // descricao: yup.string().required('A descrição é obrigatória')
})
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  textAlign: 'center',
  p: 4
}

const Transaction = () => {
  const [requisitionLoading, setRequisitionLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')
  const [transactionList, setTransactionList] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))

  const fetchTransactionList = async () => {
    try {
      const response = await axios.post(
        `https://dev-back.itcarbon.org/api/tokens/transaction/`,
        {
          userId: user.id
        }
      )
      setTransactionList(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTransactionList()
  }, [])

  function converterParaData(data) {
    const dataObjeto = new Date(data)
    const options = { timeZone: 'America/Sao_Paulo' }
    const dataBrasileira = dataObjeto.toLocaleDateString('pt-BR', options)
    return dataBrasileira
  }

  const [fornecedorList, setFornecedorList] = useState([])

  const fetchFornecedorList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/suppliers/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      setFornecedorList(response.data.landPlotList)
      console.log(response.data.landPlotList)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchFornecedorList()
  }, [])

  const {
    setValue,
    reset,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formValidation)
  })
  const [openModal, setOpenModal] = useState(false)
  const [tokenList, setTokenList] = useState([])

  const handleClose = () => setOpenModal(false)

  const userToken = localStorage.getItem('userToken')
  const token = JSON.parse(userToken)

  const handleOpen = async (id) => {
    setLoadingTitle('Aprovando proposta de troca...')
    setRequisitionLoading(true)
    try {
      const response = await axios.put(
        `https://dev-back.itcarbon.org/api/tokens/trade-request/${id}`,
        {
          status: 'approved'
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
      reset()
      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Aprovado!',
        'Troca de tokens aprovada com sucesso!',
        'success'
      ).then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Ocorreu algum erro!', 'error').then(() => {
        window.location.reload()
      })
    }
  }

  const handleRejected = async (id) => {
    setLoadingTitle('Rejeitando proposta de troca...')
    setRequisitionLoading(true)
    try {
      const response = await axios.put(
        `https://dev-back.itcarbon.org/api/tokens/trade-request/${id}`,
        {
          status: 'rejected'
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
      reset()

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Rejeitado!',
        'Troca de tokens rejeitada com sucesso!',
        'error'
      ).then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Ocorreu algum erro!', 'error').then(() => {
        window.location.reload()
      })
    }
  }

  const fetchTokenList = async () => {
    try {
      const response = await axios.get(
        `https://dev-back.itcarbon.org/api/tokens/trade-request/`
      )
      setTokenList(response.data.response)
      console.log(response.data.response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTokenList()
  }, [])

  const [supplierLogo, setSupplierLogo] = useState()

  const handleUploadFile = (file) => {
    const formData = getValues()

    const cnpjOnlyNumbers = removeCnpjMask(formData.cnpj)
    const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)

    const params = {
      Bucket: 'statics-pct',
      Key: `${cnpjOnlyNumbers}.${fileExtension}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read'
    }

    s3.upload(params, async (error, data) => {
      if (error) {
        console.log(error)
      } else {
        console.log('File uploaded successfully!', data.Location)
        setSupplierLogo(await data?.Location)
      }
    })
  }

  const onFinish = async (data) => {
    console.log(data)
    setLoadingTitle('Cadastrando fornecedor, aguarde...')
    setRequisitionLoading(true)
    try {
      await axios.post(
        'https://dev-back.itcarbon.org/api/suppliers/',
        {
          cnpj: data.cnpj,
          name: data.nome,
          logoSupplier: supplierLogo ? supplierLogo : '',
          email: data.email,
          password: data.password
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Sucesso!', 'Cadastro de fornecedor realizado', 'success').then(
        () => {
          window.location.reload()
        }
      )

      reset()
    } catch (error) {
      console.error(error)
      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Erro.',
        'Ocorreu um erro ao cadastrar o fornecedor.',
        'error'
      ).then(() => {
        window.location.reload()
      })
    }
  }

  if (requisitionLoading) {
    Swal.fire({
      title: loadingTitle,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
  }

  const handleApproveLot = async (id) => {
    setLoadingTitle('Aprovando lote do fornecedor...')
    setRequisitionLoading(true)
    try {
      const response = await axios.put(
        `https://dev-back.itcarbon.org/api/suppliers/land-plot/${id}`,
        {
          action: 'approved'
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
      reset()

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Aprovado!',
        'Lote do fornecedor aprovado com sucesso!',
        'success'
      ).then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Ocorreu algum erro!', 'error').then(() => {
        window.location.reload()
      })
    }
  }

  const handleRejectLot = async (id) => {
    setLoadingTitle('Rejeitando lote do fornecedor...')
    setRequisitionLoading(true)
    try {
      const response = await axios.put(
        `https://dev-back.itcarbon.org/api/suppliers/land-plot/${id}`,
        {
          action: 'rejected'
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      console.log(response)
      reset()

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire(
        'Rejeitado!',
        'Lote do fornecedor rejeitado com sucesso!',
        'success'
      ).then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)

      setRequisitionLoading(false)
      Swal.close()
      Swal.fire('Erro!', 'Ocorreu algum erro!', 'error').then(() => {
        window.location.reload()
      })
    }
  }

  return (
    <DashboardLayout email={user.email} name={user.username} role={user.role}>

      <Container>
        <Row className='p-3 mt-3'>
          <Col lg="6  mb-4">
<Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFFFFF',
            width: '100%'
          }}
        >
          <Box>
            <Typography
              sx={{ marginBottom: '1rem' }}
              fontWeight="600"
              color="#2F2D2B"
              fontSize={26}
            >
              Cadastro do fornecedor
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography
              color="#1F441E"
              variant="body2"
              fontWeight="500"
              marginBottom={0.2}
            >
              Nome
            </Typography>
            <Controller
              name="nome"
              control={control}
              defaultValue=""
              render={({ field: { value }, fieldState }) => (
                <Field
                  placeholder="Nome completo"
                  helperText={fieldState.error?.message}
                  error={!!errors.nome}
                  value={value}
                  id="nome"
                  name="nome"
                  autoComplete="Nome"
                  {...register('nome')}
                />
              )}
            />
          </Box>
          <Box marginBottom={2}>
            <Typography
              color="#1F441E"
              variant="body2"
              fontWeight="500"
              marginBottom={0.2}
            >
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { value }, fieldState }) => (
                <Field
                  placeholder="Email do fornecedor"
                  helperText={fieldState.error?.message}
                  error={!!errors.email}
                  value={value}
                  id="email"
                  name="email"
                  autoComplete="email"
                  {...register('email')}
                />
              )}
            />
          </Box>
          <Box marginBottom={2}>
            <Typography
              color="#1F441E"
              variant="body2"
              fontWeight="500"
              marginBottom={0.2}
            >
              Senha
            </Typography>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { value }, fieldState }) => (
                <Field
                  placeholder="Senha do fornecedor"
                  helperText={fieldState.error?.message}
                  error={!!errors.password}
                  value={value}
                  id="password"
                  name="password"
                  autoComplete="password"
                  {...register('password')}
                />
              )}
            />
          </Box>
          <Box marginBottom={2}>
            <Typography
              color="#1F441E"
              variant="body2"
              fontWeight="500"
              marginBottom={0.2}
            >
              CNPJ
            </Typography>
            <Controller
              name="cnpj"
              control={control}
              defaultValue=""
              render={({ field, fieldState }) => (
                <Field
                  {...field}
                  placeholder="0000.0000-00"
                  helperText={fieldState.error?.message}
                  error={!!errors.cnpj}
                  value={getCnpjMask(field.value)}
                  onChange={(event) =>
                    field.onChange(getCnpjMask(event.target.value))
                  }
                  id="cnpj"
                  name="cnpj"
                  autoComplete="CNPJ"
                  {...register('cnpj')}
                />
              )}
            />
          </Box>
          <Box sx={{ margin: '1rem', marginLeft: '0px' }}>
            <label htmlFor="upload-photo">
              <input
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={(event) => handleUploadFile(event.target.files[0])}
              />
              <Button
                sx={{
                  backgroundColor: '#9747FF',
                  color: 'white',
                  ':hover': {
                    backgroundColor: ' #b783ff '
                  }
                }}
                component="span"
              >
                Adicionar imagem
              </Button>
            </label>
          </Box>
          <Divider />

          <CustomButton
            sx={{ marginTop: '1rem' }}
            onClick={handleSubmit(onFinish)}
          >
            Cadastrar
          </CustomButton>
        </Box>
          </Col>
          <Col lg="6">
                  <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFFFFF',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{ marginBottom: '1rem' }}
              fontWeight="600"
              color="#2F2D2B"
              fontSize={26}
            >
              Aprovar Lotes
            </Typography>
            <IconButton
              sx={{
                width: '37px',
                height: '37px',
                borderRadius: '50%',
                padding: '0px',
                overflow: 'hidden',
                backgroundColor: '#9747FF',
                color: 'white'
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        
          <Box sx={{ width: '100%', maxHeight: '20rem' }}>
            <Paper
              style={{
                width: '100%',
                height: '30rem',
                overflow: 'auto',
                padding: 16,
                border: 'none',
                boxShadow: 'none'
              }}
            >
              <Box  sx={{minWidth: '40rem'}}>
                {fornecedorList.map((list, index) => (
                  <Box key={index}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <img
                          src={list.logoSupplier ? list.logoSupplier : Arvore}
                          alt="Imagem do Lote de Terra"
                          style={{
                            maxHeight: '10rem',
                            maxWidth: '10rem',
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                          width={200}
                        ></img>
                        <Box sx={{ margin: '1rem' }}>
                          <Typography
                            fontWeight="600"
                            color="#2F2D2B"
                            fontSize={20}
                          >
                            {list.name}
                          </Typography>
                          <Typography
                            fontWeight="500"
                            color="#2F2D2B"
                            fontSize={15}
                            sx={{ paddingTop: '0.3rem', paddingBottom: '1rem' }}
                          >
                            {list.description}
                          </Typography>
                          <Typography
                            fontWeight="400"
                            color="#31343B"
                            fontSize={18}
                          >
                            {list.area}m²
                          </Typography>
                          <Box sx={{ display: 'flex', marginTop: '0.5rem' }}>
                            <img src={Green} width={25}></img>
                            {list.value} TPC | {list.value * 100} BRL
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <IconButton
                          sx={{
                            width: '37px',
                            height: '37px',
                            borderRadius: '50%',
                            padding: '0px',
                            overflow: 'hidden',
                            backgroundColor: '#BDFEA4',
                            marginTop: '0.5rem'
                          }}
                          onClick={() => handleApproveLot(list.id)}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            width: '37px',
                            height: '37px',
                            borderRadius: '50%',
                            padding: '0px',
                            overflow: 'hidden',
                            backgroundColor: '#E25A71',
                            color: 'white',
                            marginLeft: '0.5rem',
                            marginTop: '0.5rem',
                            marginRight: '0.5rem'
                          }}
                          onClick={() => handleRejectLot(list.id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>

                    </Box>

                    <Divider></Divider>
                  </Box>
                ))}

                <Divider></Divider>
              </Box>
              {/* ))} */}
            </Paper>
          </Box>
        </Box>

          </Col>
        </Row>
        <div className='p-1'>
        <TableContainer
          sx={{
            borderRadius: 2,
            marginBottom: 3,
            backgroundColor: '#FFFFFF',
            width: '100%'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Token</TableCell>
                <TableCell>Data</TableCell>
                <TableCell sx={{ width: 140 }}>Valor</TableCell>
                <TableCell>De</TableCell>
                <TableCell>Para</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionList.map((transaction, index) => (
                <TableRow key={transaction.id}>
                  <TableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 0.8
                    }}
                  >
                    {transaction.transactionType === 'TCC' && (
                      <img src={CoinPurple} width="100%" />
                    )}
                    {transaction.transactionType === 'TPC' && (
                      <img src={CoinGreen} width="100%" />
                    )}
                    {transaction.transactionType === 'TDC' && (
                      <img src={CoinRed} width="100%" />
                    )}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#6F7380',
                        marginTop: -0.6
                      }}
                    >
                      {transaction.transactionType}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ color: '#6F7380' }}>
                    {converterParaData(transaction?.createdAt)}
                  </TableCell>
                  <TableCell sx={{ color: '#6F7380' }}>
                    {transaction.amount}
                  </TableCell>
                  <TableCell sx={{ color: '#6F7380' }}>
                    {transaction.to.address && (
                      <Link
                        target="_blank"
                        sx={{ color: '#6F7380' }}
                        underline="none"
                        href={`https://mumbai.polygonscan.com/address/${transaction.to.address}#tokentxns`}
                      >
                        {transaction.to.address}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell sx={{ color: '#6F7380' }}>
                    {transaction.from.address && (
                      <Link
                        target="_blank"
                        sx={{ color: '#6F7380' }}
                        underline="none"
                        href={`https://mumbai.polygonscan.com/address/${transaction.from.address}#tokentxns`}
                      >
                        {transaction.from.address}
                      </Link>
                    )}
                  </TableCell>
              

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
      </Container>





      
    </DashboardLayout>
  )
}

export default Transaction
