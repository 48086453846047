import { CustomButton } from "../../styles";
import { RemoveRedEyeOutlined, ParkOutlined } from "@mui/icons-material";
import { Link as RouterLink } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  styled,
  Link,
} from "@mui/material";
import axios from "axios";
import Purple from "../../assets/coin-purple.svg"
import Green from "../../assets/coin-green.svg"
import Red from "../../assets/coin-red.svg"
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";

const TransactionTable = (props) => {
  const StyledTable = styled(Table)({
    "& td": {
      borderBottom: "none",
    },
  });

  const [transactionList, setTransactionList] = useState([]);

  const fetchTransactionList = async () => {
    try {
      const response = await axios.post(
        `https://dev-back.itcarbon.org/api/tokens/transaction/`, {
          slug: props.slug ? props.slug : '',
          userId: props.userId ? props.userId : ''
        }
      );
      setTransactionList(response.data.response);
      console.log(response.data.response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTransactionList();
  }, []);

  function converterParaData(data) {
    const dataObjeto = new Date(data);
    const options = { timeZone: "America/Sao_Paulo" };
    const dataBrasileira = dataObjeto.toLocaleDateString("pt-BR", options);
    return dataBrasileira;
  }


  const columns = [
    { 
      field: 'token',
      headerName: 'Token',
      width: 150,
      renderCell: (params) => (
        <TableCell>
          {params.value === 'TCC' && (
            <div style={{display: 'flex'}}>
            <div>
            <img src={Purple} alt="Purple" width="100%" />
            </div>
            <div>
             TCC
            </div>
           </div>
          )}
          {params.value === 'TPC' && (
            <div style={{display: 'flex'}}>
             <div>
             <img src={Green} alt="Green" width="100%" />
             </div>
             <div>
              TPC
             </div>
            </div>
          )}
          {params.value === 'TDC' && (
           <div style={{display: 'flex'}}>
           <div>
           <img src={Red} alt="Red" width="100%" />
           </div>
           <div>
            TDC
           </div>
          </div>
          )}
          
        </TableCell>
      ),
    },
    { field: 'data', headerName: 'Data', width: 150 },

    { field: 'valor', headerName: 'Valor', width: 150 },
    {
      field: 'De',
      headerName: 'De',
      width: 500,
      renderCell: (params) => (
        <TableCell>
          {params.value && (
            <Link
              target="_blank"
              sx={{ color: "#6F7380" }}
              underline="none"
              href={`https://mumbai.polygonscan.com/address/${params.value}#tokentxns`}
            >
              {params.value}
            </Link>
          )}
        </TableCell>
      ),
    },
    {
      field: 'Para',
      headerName: 'Para',
      width: 500,
      renderCell: (params) => (
        <TableCell>
          {params.value && (
            <Link
              target="_blank"
              sx={{ color: "#6F7380" }}
              underline="none"
              href={`https://mumbai.polygonscan.com/address/${params.value}#tokentxns`}
            >
              {params.value}
            </Link>
          )}
        </TableCell>
      ),
    },
      
    { field: 'terreno', headerName: 'Visualizar terreno', width: 300, renderCell: (params) => {
      if (params.row.token === 'TPC') {
        return (
          <TableCell>
            <Link
              target="_blank"
              sx={{ color: "#6F7380" }}
              underline="none"
              href={`/grounds`}
            >
              Acessar terreno
            </Link>
          </TableCell>
        );
      }
    
      return <TableCell />;
    }},

    
    { field: 'button',
    headerName: '',
    width: 200,
    renderCell: (params) => (
      <TableCell>
        <Link
          target="_blank"
          sx={{ color: "#6F7380" }}
          underline="none"
          href={`https://mumbai.polygonscan.com/address/0x47d2bb613bb5d4e155b24bde8378471aa4983f0c`}
        >
          <CustomButton sx={{ width: "34px", height: "37px" }}>
            <RemoveRedEyeOutlined />
          </CustomButton>
        </Link>
      </TableCell>
    ),},
  ];


  const rows = transactionList.map((list) => ({
    id: list.id,
    data: converterParaData(list?.createdAt),
    token: list.transactionType ,
    valor: list.amount,
    De: list.to.address,
    Para: list.from.address,
    terreno: 'https://www.google.com/',
  }));
  
  return (
    <Box sx={{
      marginTop: '0px',
    }}>
   <div style={{ height: 300, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
      />
    </div>
{/* 
   <TableContainer
          sx={{
            borderRadius: 2,
            marginBottom: 3,
            backgroundColor: "#FFFFFF",
            marginTop: "1rem",
            width: '100%',

          }}
        >
          <Table>

        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: { xs: 12, xl: 14 },
              }}
            >
              Token
            </TableCell>
            <TableCell
              sx={{
                fontSize: { xs: 12, xl: 14 },
              }}
            >
              Data
            </TableCell>
            <TableCell sx={{ fontSize: { xs: 12, xl: 14 }, width: 120 }}>
              Valor
            </TableCell>
            <TableCell
              sx={{
                fontSize: { xs: 12, xl: 14 },
              }}
            >
              Carteira de envio
            </TableCell>
            <TableCell
              sx={{
                fontSize: { xs: 12, xl: 14 },
              }}
            >
              Carteira de recebimento
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionList.map((list) => (
            <TableRow key={list.id}>
              <TableCell
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 0.8,
                }}
              >
                <Box
                  sx={{
                    width: { xs: "28px", xl: "38px" },
                  }}
                >
                  {list.transactionType === "TCC" && (
                    <img src={Purple} width="100%" />
                  )}
                  {list.transactionType === "TPC" && (
                    <img src={Green} width="100%" />
                  )}
                  {list.transactionType === "TDC" && (
                    <img src={Red} width="100%" />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: 12, xl: 14 },
                    color: "#6F7380",
                    marginTop: -0.6,
                  }}
                >
                  {list.transactionType}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ fontSize: { xs: 12, xl: 14 }, color: "#6F7380" }}
              >
                {converterParaData(list?.createdAt)}
              </TableCell>
              <TableCell
                sx={{ fontSize: { xs: 12, xl: 14 }, color: "#6F7380" }}
              >
                {list.amount}
              </TableCell>
              <TableCell
                sx={{ fontSize: { xs: 12, xl: 14 }, color: "#6F7380" }}
              >
                {list.from.address && (
                  <Link
                    target="_blank"
                    sx={{ color: "#6F7380" }}
                    underline="none"
                    href={
                      `https://mumbai.polygonscan.com/address/${list.from.address}#tokentxns`
                    }
                  >
                    {list.from.address}
                  </Link>
                )}
              </TableCell>
              <TableCell
                sx={{ fontSize: { xs: 12, xl: 14 }, color: "#6F7380" }}
              >
                {list.to.address && (
                  <Link
                    target="_blank"
                    sx={{ color: "#6F7380" }}
                    underline="none"
                    href={
                      `https://mumbai.polygonscan.com/address/${list.to.address}#tokentxns`
                    }
                  >
                    {list.to.address}
                  </Link>
                )}
              </TableCell>
              <TableCell sx={{ width: 70 }}>
                <Link
                  target="_blank"
                  sx={{ color: "#6F7380" }}
                  underline="none"
                  href={
                    "https://mumbai.polygonscan.com/address/0x47d2bb613bb5d4e155b24bde8378471aa4983f0c"
                  }
                >
                  <CustomButton sx={{ width: "34px", height: "37px" }}>
                    <RemoveRedEyeOutlined />
                  </CustomButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
    </TableContainer> */}
    </Box>

  );
};

export default TransactionTable;
