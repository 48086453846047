import { Button, TextField, Typography, styled } from "@mui/material";

export const CustomButton = styled(Button)(({ secondarycolor }) => ({
  width: "100%",
  height: "45px",
  fontSize: "18px",
  fontWeight: "500",
  letterSpacing: "0.7px",
  textTransform: "none",
  color: "#FFFFFF",
  backgroundColor: "#9747FF",
  borderRadius: 40,
  "&:hover": {
    backgroundColor: "#9747FF",
    opacity: "0.8",
  },
  ...(secondarycolor && {
    color: "#2F2D2B",
    backgroundColor: secondarycolor,
    "&:hover": {
      backgroundColor: secondarycolor,
      opacity: "0.8",
    },
  }),
}));

export const Field = styled(TextField)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  borderRadius: "10px",

  [theme.breakpoints.up("xl")]: {
    "& .MuiInputBase-root": {
      height: "76px",
      fontSize: "22px",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(255, 65, 65)",
      fontWeight: 400,
      fontSize: "18px",
      marginTop: "8px",
    },
  },
  "& .MuiInputBase-root": {
    border: "0.2px solid #C6C6C6",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#93979e",
    fontSize: "22px",
  },
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiFormHelperText-root": {
    color: "rgb(255, 65, 65)",
    fontWeight: 400,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
}));
